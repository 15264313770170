import React from "react";
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, Button} from "@nextui-org/react";
import { Link as ScrollLink } from 'react-scroll';


export default function Header({
       name = "Product name",
       href = "#",
       links=[]
   }) {


    return (

    <Navbar className="bg-[#1E1E1E] text-[#FDFFFC]" >
        <NavbarBrand>
            <ScrollLink to="HeroSection" smooth={true} >
                <p className="text-lg hover:cursor-pointer">{name}</p>
            </ScrollLink>
        </NavbarBrand>

        <NavbarContent className="hidden sm:flex gap-4" justify="center">
            {links.map((item, index) => (
                <NavbarItem>
                <ScrollLink smooth={true} className="text-[#FDFFFC]" to={item.url}>
                    {item.name}
                </ScrollLink>
                </NavbarItem>
            ))}
        </NavbarContent>
        <NavbarContent justify="end">
            <NavbarItem>
                <Button radius={"full"} className={"bg-[#1D65C1] text-[#FDFFFC]"} href={href} as={Link}>
                    Buy
                </Button>
            </NavbarItem>
        </NavbarContent>
    </Navbar>
    );
}
