import React from "react";
import {Link} from "react-router-dom";
import clsx from "clsx";
import {twMerge} from "tailwind-merge";

// All the cards will have a default text and default image, with this we don't have problem if we forget write a param
export default function ProductCard(props)
{

    const isWhite = props.isWhite ? props.isWhite : false

    const className = twMerge(clsx())

    const classNames = {
        card: twMerge(clsx(
            'cursor-pointer max-lg:my-4 shadow-md gap-4 group grid grid-cols-2 h-[200px] lg:w-[456px] mx-auto rounded-lg border-solid border  border-[#dbdbdb]',
            {
                "bg-[#FDFFFC] text-[#1D1D1D]": isWhite,
                "bg-[#1D1D1D] text-[#FDFFFC]": !isWhite,
            }
            ), props.classNames?.card),
        leftSide: twMerge(clsx('px-4 py-4 grid grid-rows-3'), props.classNames?.leftSide),
        title: twMerge(clsx(
            'row-span-1 max-h-[24px] my-2 text-base sm:text-lg font-semibold bg-clip-text md:text-2xl',
                {
                    "from-[#6442A6] to-[#2B5184]": isWhite,
                    "from-pink-500 to-violet-500": !isWhite,
                }
            ), props.classNames?.title),
        description: twMerge(clsx('row-span-2 max-h-[60px] my-2 text-xs  line-clamp-2'), props.classNames?.description),
        button: twMerge(clsx('row-span-2  inset-x-0 bottom-0 justify-center w-[90px] h-[30px]  my-2 text-xs text-white bg-[#1D65C1] p-2 rounded-full'), props.classNames?.button),
        rightSide: twMerge(clsx('aspect-w-16 aspect-h-9 overflow-hidden rounded-r-lg max-h-[198px]'), props.classNames?.rigthSide),
        image: twMerge(clsx('object-contain size-full content-center justify-center cursor-pointer rounded-r-lg'), props.classNames?.image),
    }

  return (

      <Link to={props.url} className={className}>
        <div className={classNames.card}>
          <div className={classNames.leftSide}>
            <p className={classNames.title}>
              {props.name}
            </p>

            <p className={classNames.description}>
              {props.description}
            </p>

            <button className={classNames.button}>
              More
            </button>
          </div>
          <div className={classNames.rightSide}>
            <img
              className={classNames.image}
              src={props.image}
              alt={props.title + " Product Image"}
            />
          </div>
        </div>
    </Link>

  );
}
