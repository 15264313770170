import React from "react";
import { Button } from "@nextui-org/react";
import { Accordion, AccordionItem } from "@nextui-org/accordion";

function TechnicalSpecifications() {
  const defaultContent =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.";
  return (
    <section
      name="specifications"
      className="p-0 m-0 py-36 bg-[#EBEDEA] w-[100%]"
    >
      <div className="flex-row lg:grid lg:gap-2 lg:grid-cols-6 px-6 text-[#1D1D1D] mx-auto content-center max-w-[1144px]">
        <div className="pl-2 mb-4 col-span-6">
          <p className="text-4xl font-semibold md:text-left">
            Technical specifications
          </p>
          <p className="text-xl">Minimalist from the root</p>
        </div>
        <div className="relative lg:col-span-2  lg:h-auto h-[256px] w-[100%] order-last bg-slate-200">
          
        </div>
        <div className="lg:col-span-4 items-center lg:pe-[100px]">
          <Accordion>
            <AccordionItem
              classNames={{
                title: "font-medium",
                content: "text-[#1D1D1D] font-normal text-sm",
              }}
              key="1"
              aria-label="Accordion 1"
              title="Dimensions"
            >
              {"Lorem ipsum dolor sit"}
            </AccordionItem>
            <AccordionItem
              classNames={{
                title: "font-medium",
                content: "text-[#1D1D1D] font-normal text-sm",
              }}
              key="2"
              aria-label="Accordion 2"
              title="Technology"
            >
              {"Lorem ipsum dolor sit"}
            </AccordionItem>
            <AccordionItem
              classNames={{
                title: "font-medium",
                content: "text-[#1D1D1D] font-normal text-sm",
              }}
              key="3"
              aria-label="Accordion 3"
              title="User Profile"
            >
              {"Lorem ipsum dolor sit"}
            </AccordionItem>
            <AccordionItem
              classNames={{
                title: "font-medium",
                content: "text-[#1D1D1D] font-normal text-sm",
              }}
              key="4"
              aria-label="Accordion 4"
              title="Server"
            >
              {"Lorem ipsum dolor sit"}
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </section>
  );
}

export default TechnicalSpecifications;