import React, { useEffect } from "react";
import { Parallax } from "react-scroll-parallax";
import { animate, motion, useMotionValue } from "framer-motion";
import { useMeasure } from "@uidotdev/usehooks";
import { cardsType } from "./data";

const SquareDesign = 
"h-32 w-32 m-5 max-md:text-tiny text-white rounded-xl shadow-xl flex items-end justify-start bg-[#1D1D1D]";


export function Square({ name = "name", background = "#020303", className }) {
  // Determina si el background es una URL de imagen (comienza con '#')
  const isColor = typeof background === "string" && background.startsWith("#");

  // Define el estilo en línea basado en el tipo de background
  const style = isColor
    ? { background }
    : {
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      };

  return (
    <div
      className={`md:h-28 2xl:h-32 2xl:w-32 md:w-28 h-16 w-16 m-5 p-2 max-md:text-tiny text-white rounded-xl shadow-2xl flex items-end justify-start ${className}`}
      style={style}
    >
      {name}
    </div>
  );
}

export function TopChooseSquares() {
  let [ref, { width }] = useMeasure();

  const xTranslation = useMotionValue(0);

  useEffect(() => {
    let controls;
    let finalPosition = -width / 2 - 8;

    controls = animate(xTranslation, [0, finalPosition], {
      ease: "linear",
      duration: 50,
      repeat: Infinity,
      repeatType: "loop",
      repeatDelay: 0,
    });

    return controls.stop;
  }, [xTranslation, width]);

  return (
    <main>
      {/* top carousel */}
      <motion.div
        className="absolute left-0 top-0 max-md:top-24 flex gap-4"
        style={{ x: xTranslation }}
        ref={ref}
      >
        {cardsType
          .filter((_, index) => index < cardsType.length / 2 + 1)
          .map((value, index) => (
            <Square key={index} name={value.name} background={value.image} />
          ))}

        {/* these divs are cloned to create the infinity effect */}
        {cardsType
          .filter((_, index) => index < cardsType.length / 2 + 1)
          .map((value, index) => (
            <Square key={index} name={value.name} background={value.image} />
          ))}
      </motion.div>
    </main>
  );
}

export function BottomChooseSquares() {
  let [ref, { width }] = useMeasure();

  const xTranslation = useMotionValue(0);

  useEffect(() => {
    let controls;
    let finalPosition = -width / 2 - 8;

    controls = animate(xTranslation, [finalPosition, 0], {
      ease: "linear",
      duration: 50,
      repeat: Infinity,
      repeatType: "loop",
      repeatDelay: 0,
    });

    return controls.stop;
  }, [xTranslation, width]);

  return (

    <main>
      {/* bottom carousel */}
      <motion.div
        className="absolute left-0 bottom-0 flex gap-4 mb-20"
        style={{ x: xTranslation }}
        ref={ref}
      >
        {cardsType
          .filter((_, index) => index >= cardsType.length / 2 + 1)
          .map((value, index) => (
            <Square key={index} name={value.name} background={value.image} />
          ))}
        {/* these divs are cloned to create the infinity effect */}
        {cardsType
          .filter((_, index) => index >= cardsType.length / 2 + 1)
          .map((value, index) => (
            <Square key={index} name={value.name} background={value.image} />
          ))}
      </motion.div>
    </main>
  );
}

export function PlasticSquares({ material }) {
  const filterCard = cardsType.filter((value, _) => value.type.toLowerCase().includes(material.toLowerCase()));

  const middleIndex = Math.ceil(filterCard.length / 2);
  const firstHalf = filterCard.slice(0, middleIndex);
  const secondHalf = filterCard.slice(middleIndex);

  return (
    <div className="h-[100vh]">
      <Parallax className="grid grid-cols-3 gap-4 md:h-full items-center justify-items-center">
        <div className="col-span-1 space-y-14">
          {firstHalf.map((value, index) => (
            <Parallax
              key={index}
              scale={[0, 1, "easeInOutQuad"]}
              opacity={[0, 1, "easeInOutCirc"]}
              translateY={[-50, 0, "easeInOutQuad"]}
              speed={5}
            >
              <Square key={index} name={value.name} background={value.image} />
            </Parallax>
          ))}
        </div>
        <div className="col-span-1" />
        <div className="col-span-1 space-y-14">
          {secondHalf.map((value, index) => (
            <Parallax
              key={index}
              scale={[0, 1, "easeInOutQuad"]}
              opacity={[0, 1, "easeInOutCirc"]}
              translateY={[100, 0, "easeInOutQuad"]}
              speed={5}
            >
              <Square key={index} name={value.name} background={value.image} />
            </Parallax>
          ))}
        </div>
      </Parallax>
    </div>
  );
}

