import React, { useContext, useState } from "react";
import { Input, Button } from "@nextui-org/react";
import { StepperContext } from "../Context/StepperContext";

export default function Journey({ handleClick, currentStep, steps }) {
  return (
    <div>
      <p className="my-6 text-3xl font-semibold">Introduce</p>
      <p className="">
        Becoming an Astrobie Academy partner as an individual means joining a
        global network dedicated to transforming education and making a lasting
        impact on students worldwide. This partnership offers you the
        opportunity to engage in meaningful projects, participate in educational
        events, and collaborate with like-minded individuals. By partnering with
        Astrobie Academy, you will play a crucial role in creating a more
        inclusive and equitable educational landscape, empowering the next
        generation of leaders and thinkers.{" "}
        <span className="text-[#1D64C1]">
          Be part of the legacy of education.
        </span>
      </p>
      <div className="container flex justify-between mt-20 mb-4">
        <Button
          onClick={() => handleClick()}
          isDisabled={true}
          className={`bg-[#FDFFFC] text-[#1D1D1D] px-4 py-2 rounded-full
font-semibold cursor-pointer border-1 border-[#1D1D1D] hover:text-[#FDFFFC] hover:bg-[#1D1D1D] transition duration-200 ease-in-out ${
            currentStep === 1
              ? "opacity-50 cursor-not-allowed"
              : "cursor-pointer"
          }`}
        >
          Back
        </Button>

        <Button
          onClick={() => handleClick("next")}
          className="bg-[#1D65C1] px-4 py-2 rounded-full font-medium border-1 border-[#1D65C1] text-[#FDFFFC] cursor-pointer"
        >
          {currentStep === steps.length ? "Confirm" : "Next"}
        </Button>
      </div>
    </div>
  );
}
