import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenuItem,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger
} from "@nextui-org/react";
//   NavbarMenuToggle,
//   NavbarMenu,
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import SearchBar from "./SearchBar";
import AstrobieLogo from "./resources/logos/AstrobieLogo";

function NavBarSticky() {
  const [showNav, setShowNav] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(false);

  const handleMenuItemClick = () => {

    setActiveDropdown(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setShowNav(true);
      } else {
        setShowNav(false);
      }
    });
  }, []);

  const menuItems = [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "Outsourcing",
      // url: "outsourcing",
    },
    {
      name: "Products",
      // url: "products",
    },
    {
      name: "Academy",
      url: "/academy",
    },
    {
      name: "About",
      url: "about",
    },
  ];

  const ProductsItems = [
    {

      key: "Nova Card",
      name: "Nova Card",
      url: "/products"

    }
  ]

  const OutsourcingItems = [
    {
      key: "webPage",
      name: "Web Page",
      url: "/outsourcing"
    },
    {
      key: "mobileApp",
      name: "Mobile App",
      url: "/outsourcing"
    }
  ]


  return (
    <AnimatePresence>
      {showNav && (
        <Navbar
          maxWidth={"2xl"}
          className={`fixed bg-transparent backdrop-blur-none ${showNav ? "max-lg:visible" : "invisible"
            }`}
        >
          {showNav && (
            <motion.div
              initial={{ opacity: 0, y: -30, z: 0 }}
              animate={{ opacity: 1, y: 1, z: 0 }}
              exit={{ opacity: 0, y: -20, z: -20 }}
              transition={{ duration: 0.42 }}
              className="w-full h-full"
            >
              <NavbarContent
                className="hidden sm:flex gap-4 mt-[6px] px-5 bg-[#1d1d1d] text-[#FDFFFCC1] rounded-full shadow-md"
                justify="end"
              >
                <NavbarBrand className="ps-1.5">
                  <Link to={"/"}>
                    <AstrobieLogo />
                  </Link>
                </NavbarBrand>
                {menuItems.map((item, index) => (
                  (item.name === "Products" || item.name === "Outsourcing") ? (

                    <NavbarMenuItem key={item.name}>
                      {["Outsourcing", "Products"].includes(item.name) ? (
                        <Dropdown isOpen={activeDropdown === item.name} className="bg-[#1d1d1d] text-[#fdfffc] pt-1 mt-1 ml-0 rounded-t-none" style={{ zIndex: 10 }} placement="bottom-start">
                          <DropdownTrigger>
                            <Link to={item.url} onClick={handleMenuItemClick} onMouseEnter={() => setActiveDropdown(item.name)} >
                              <li className="hover:text-[#808996] text-medium">{item.name}</li>
                            </Link>
                          </DropdownTrigger>
                          <DropdownMenu className="pr-4 my-2" onMouseLeave={() => setActiveDropdown(null)}>
                            {(item.name === "Outsourcing" ? OutsourcingItems : ProductsItems).map((subItem) => (
                              <DropdownItem key={subItem.name}>
                                <Link to={subItem.url}>{subItem.name}</Link>
                              </DropdownItem>
                            ))}
                          </DropdownMenu>


                        </Dropdown>
                      ) : (
                        <Link to={item.url} onClick={handleMenuItemClick} >
                          <li className="hover:text-[#808996] text-medium">{item.name}</li>
                        </Link>
                      )}

                    </NavbarMenuItem>
                  ) : (
                    <NavbarMenuItem key={`${item}-${index}`}>
                      <Link to={item.url} onClick={handleMenuItemClick}>
                        <li className="hover:text-[#fdfffc] duration-250 text-medium" >{item.name}</li>
                      </Link>
                    </NavbarMenuItem>
                  )
                ))}

                <NavbarItem className="max-md:hidden">
                  <SearchBar IsInFloatNav={true} />
                </NavbarItem>
              </NavbarContent>
            </motion.div>
          )}
        </Navbar>
      )}
    </AnimatePresence>
  );
}

export default NavBarSticky;
