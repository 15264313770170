import React from 'react';
import { motion } from "framer-motion";


function AAStory() {   

    return (
        <section className='bg-[#FDFFFC] text-[#1D1D1D] w-[100%] flex-col items-center justify-center'>
            <div className='mt-20 mb-24 md:mt-24 md:mb-24 px-6 mx-auto flex-column content-center static gap-8 max-w-[1144px]'>
                <div className="text-4xl font-semibold mb-24">
                    <span>
                        History
                    </span>
                </div>
                <ul>
                    <li className='relative flex gap-6 pb-5'>
                        <motion.div 
                            className='before:absolute before:left-[5.5px] before:h-full before:w-[4px] before:bg-[#1D64C1]'>
                            <div className='h-[15px] absolute w-[15px] bg-[#1D64C1] border-[#FDFFFC] border-2 rounded-full'></div>
                        </motion.div>
                        <motion.div 
                            initial={{opacity: 0, y: 100}}
                            whileInView={{opacity: 1, y: 0}}
                            transition={{duration: 0.7}}
                            viewport={{once: true}}
                            className='pb-24 ml-8'>
                            <p className='text-[#6650d5]'>2020</p>
                            <p className='text-2xl font-semibold'>Our humble beginning</p>
                            <p className='mt-2 text-sm max-w-[900px]'>In 2020, as the COVID-19 pandemic began and the world went into lockdown, Astrobie LLC Co-Founder <span className='text-[#6650d5]'>Isaac Orozco</span> conceived the idea of creating an educational online platform focused on programming tutorials. His goal was to develop a transformative platform for university students struggling with coding. Initially named CodePanda, the project faced resource constraints that hindered its growth and expansion.</p>
                        </motion.div>
                    </li>
                    <li className='relative flex gap-6 pb-5'>
                        <motion.div 
                            className='before:absolute before:left-[5.5px] before:h-full before:w-[4px] before:bg-[#1D64C1]'>
                            <div className='h-[15px] absolute w-[15px] bg-[#1D64C1] border-[#FDFFFC] border-2 rounded-full'></div>
                        </motion.div>
                        <motion.div 
                            initial={{opacity: 0, y: 100}}
                            whileInView={{opacity: 1, y: 0}}
                            transition={{duration: 0.7}}
                            viewport={{once: true}}
                            className='pb-24 ml-8'>
                            <p className='text-[#ff6464]'>2021</p>
                            <p className='text-2xl font-semibold'>Friends reunited</p>
                            <p className='mt-2 text-sm max-w-[900px]'>Astrobie LLC Founder <span className='text-[#ff6464]'>Johan Olmos</span> initiated his own educational platform. At the time, Johan and Isaac, who knew each other from high school, were unaware that they were working on similar projects. Despite this, Olmos continued to structure his vision for Astrobie Academy. Later that year, Olmos and Orozco shared their ideas and realized they had a similar goal: building an educational platform. They decided that combining their efforts would yield better results. This mutual understanding led to their collaboration, merging their projects to create a unique and impactful educational platform.</p>
                        </motion.div>
                    </li>
                    <li className='relative flex gap-6 pb-5'>
                        <motion.div 
                            className='before:absolute before:left-[5.5px] before:h-full before:w-[4px] before:bg-[#1D64C1]'>
                            <div className='h-[15px] absolute w-[15px] bg-[#1D64C1] border-[#FDFFFC] border-2 rounded-full'></div>
                        </motion.div>
                        <motion.div 
                            initial={{opacity: 0, y: 100}}
                            whileInView={{opacity: 1, y: 0}}
                            transition={{duration: 0.7}}
                            viewport={{once: true}}
                            className='pb-24 ml-8'>
                            <p className='text-[#ffa664]'>2022</p>
                            <p className='text-2xl font-semibold'>Emerged from stardust</p>
                            <p className='mt-2 text-sm max-w-[900px]'>In this pivotal year, after extensive work and effort, both friends finalized an initial product and decided to expand it. Olmos believed it was time to test if Astrobie Academy was suitable and if its mission would resonate with others. This beta version, though far from the final product, was sufficient to begin seeking resources and attracting interested individuals. They started presenting their idea to the world, aiming to gather feedback and support. This phase involved reaching out to potential collaborators, investors, and users to validate their concept and refine their platform based on real-world input. Their goal was to ensure that Astrobie Academy could truly make a meaningful impact on education.</p>
                        </motion.div>
                    </li>
                    <li className='relative flex gap-6 pb-5'>
                        <motion.div 
                            className='before:absolute before:left-[5.5px] before:h-full before:w-[4px] before:bg-[#1D64C1]'>
                            <div className='h-[15px] absolute w-[15px] bg-[#1D64C1] border-[#FDFFFC] border-2 rounded-full'></div>
                        </motion.div>
                        <motion.div 
                            initial={{opacity: 0, y: 100}}
                            whileInView={{opacity: 1, y: 0}}
                            transition={{duration: 0.7}}
                            viewport={{once: true}}
                            className='pb-24 ml-8'>
                            <p className='text-[#5ab489]'>2023</p>
                            <p className='text-2xl font-semibold'>Networking</p>
                            <p className='mt-2 text-sm max-w-[900px]'>At this moment, Olmos realized that the Astrobie Academy project required further development. He took on the task of negotiating and expanding their vision. This led him to travel overseas to Kenya, where he visited local communities and truly grasped the significance of Astrobie Academy. Reflecting on this experience, he said, “From that exact moment of my life, I understood that Astrobie Academy was something special that could change someone's life. I got inspired picturing myself and my team being the helping hand for talented individuals.”</p>
                        </motion.div>
                    </li>
                    <li className='relative flex gap-6 pb-5'>
                        <motion.div 
                            className='before:absolute before:left-[5.5px] before:h-full before:w-[4px] before:bg-[#1D64C1]'>
                            <div className='h-[15px] absolute w-[15px] bg-[#1D64C1] border-[#FDFFFC] border-2 rounded-full'></div>
                        </motion.div>
                        <motion.div 
                            initial={{opacity: 0, y: 100}}
                            whileInView={{opacity: 1, y: 0}}
                            transition={{duration: 0.7}}
                            viewport={{once: true}}
                            className='pb-24 ml-8'>
                            <p className='text-[#6650d5]'>2024</p>
                            <p className='text-2xl font-semibold'>Stronger Together</p>
                            <p className='mt-2 text-sm max-w-[900px]'>Thanks to our growing number of volunteer members, Astrobie Academy is on the verge of becoming a reality. This year, essential partners have joined our mission, making us stronger together. In 2024, the Astrobie Academy community has seen significant growth, with members and partners in Mexico, the United States, Colombia, Kenya, and India. As Olmos stated, “Their effort will build a legacy of opportunities for others.”</p>
                        </motion.div>
                    </li>
                    <li>
                        <motion.div
                            initial={{opacity: 0, y: 100}}
                            whileInView={{opacity: 1, y: 0}}
                            transition={{duration: 0.7}}
                            viewport={{once: true}}
                            className='mt-2'>
                            <p className='text-4xl text-[#1d64c17e] font-semibold'>There is much more</p>
                            <p className='text-4xl text-[#1d1d1d] font-semibold'>Astrobie Academy is getting ready</p>
                        </motion.div>
                    </li>
                </ul>

            </div>
        </section>
    )
}


export default AAStory