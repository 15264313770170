import React from 'react'

function TermsConditions() {
    return (
        <section className='p-0 m-0 py-36 bg-[#FDFFFC]  flex-col items-center justify-center w-[100%]'>
            <div className=" px-6 text-[#1D1D1D] mx-auto flex-column content-center static gap-8 max-w-[1144px]">
                <div className="pl-2">
                    <p className="text-4xl font-semibold text-left">Frequently Asked Questions</p>
                    <p className='text-xl text-[#1D1D1D] mb-8'>Quick answers to questions you may have</p>
                </div>

                <div>

                </div>

            </div>
        </section>
    )
}

export default TermsConditions