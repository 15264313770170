import React from "react";
import {
    Card,
    CardBody,
    CardFooter,
    Image,
    Button,
    Divider,
} from "@nextui-org/react";
import BasicModal from "../BasicModal";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { SwiperSlide } from "swiper/react";

function NewsCards(props) {
    return (
        <div>
            <SwiperSlide>
                <Card className="max-w-[456px] h-[400px] cursor-grab focus:cursor-grabbing col-span-12 sm:col-span-5 mb-16">
                    <Image
                        className="object-fill"
                        alt={props.title + " Product Image"}
                        src={props.image}
                    />
                    <CardBody className="z-10 absolute text-[#FDFFFC]">
                        <p className="text-2xl font-medium">{props.title}</p>
                        <p className="text-medium font-normal">{props.subtitle}</p>
                    </CardBody>
                    <CardFooter className="bg-gradient-to-t from-[#060606] to-[#06060604] h-auto absolute bottom-0 z-10 px-4 py-4">
                        <BasicModal
                            size="xl"
                            header={() =>
                                <>
                                    <h4 className="text-2xl">{props.title}</h4>
                                    <p className="text-sm font-normal">{props.subtitle}</p>
                                </>}
                            body={() => (
                                <>
                                    <Divider className="my-4" />
                                    <p>{props.body}</p>
                                </>
                            )}
                            button={(handleClick) => (
                                <Button
                                    isIconOnly
                                    className="bg-[#1D65C1] hover:bg-[#2b51cc] rounded-full text-[#FDFFFC] text-lg"
                                    onClick={handleClick}
                                >
                                    &#43;
                                </Button>
                            )}
                            classNames={{ all: "" }}
                        />
                    </CardFooter>
                </Card>
            </SwiperSlide>
        </div>
    );
}

export default NewsCards;