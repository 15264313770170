import React, { useEffect, useState } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import MaterialSection from "./MaterialSection";
import { BottomChooseSquares, TopChooseSquares } from "./SquaresAnimations";
import { cardsType } from "./data";

const ScrollParallax = () => {
  const sectionsCards = [
    {
      title: "Plastic",
      subtitle: "Make sure you love it",
      description:
        "Decide which material fits better your needs. You can also opt to Decide which material fits better your needs. You can also opt to Decide which material fits better your needs. You can also opt to Sustainable materials available.",
    },
    {
      title: "Stainless Steel",
      subtitle: "Make sure you love it",
      description:
        "Decide which material fits better your needs. You can also opt to Decide which material fits better your needs. You can also opt to Decide which material fits better your needs. You can also opt to Sustainable materials available.",
    },
    {
      title: "Earth Care",
      subtitle: "Make sure you love it",
      description:
        "Decide which material fits better your needs. You can also opt to Decide which material fits better your needs. You can also opt to Decide which material fits better your needs. You can also opt to Sustainable materials available.",
    },
  ];

  return (
    <section
      name="materials"
      className="bg-[#FDFFFC] px-6 m-auto text-[#1D1D1D] w-[100%] flex items-center justify-center"
    >
      <div className="max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24  items-center justify-center">
        <ParallaxProvider>
          {/*general type */}
          <div className="relative w-full max-w-7xl h-[80vh] flex justify-center items-center overflow-hidden">
            {/* center container */}
            <div className="absolute flex flex-col items-center text-center justify-center z-30">
              <p className="text-4xl font-semibold">Get To Choose Your</p>
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#1D64C1] to-[#CD8987] text-[5rem] font-semibold">
                Material
              </span>
              {/* <p className="text-xl text-[#1D64C1] mb-3 font-semibold">Make sure you love it</p> */}
            </div>
            <TopChooseSquares />
            <BottomChooseSquares />
          </div>

          {/*types of card */}
          {/* The sections have been created in a dynamic way */}
          <div className="relative w-full h-auto">
            {sectionsCards.map((section, index) => (
              <div
                className={`sticky top-0 h-[180vh] w-full transition-opacity duration-500 ease-in-out opacity-100 z-${
                  (index + 1) * 10
                } bg-[#fcfffd]`}
              >
                <MaterialSection
                  title={section.title}
                  subtitle={section.subtitle}
                  description={section.description}
                  right={(index + 1) % 2 === 0}
                  material={section.title}
                />
              </div>
            ))}

            <div className="sticky top-0 h-[90vh] z-30" />
          </div>
        </ParallaxProvider>
      </div>
    </section>
  );
};

export default ScrollParallax;
