export const NewsData = [
    {
        id: 1,
        title: "Astrobie in Africa",
        subtitle: "Expanding our horizons",
        image: "https://images.pexels.com/photos/1667850/pexels-photo-1667850.jpeg",
        heading: "Kenyan family",
        body: "Early this month, Astrobie became stronger by the integration of new special members from Kenya, a country in east Africa. Our goal has been always improving humans' lives by getting stronger together.",
    },
    {
        id: 2,
        title: "Chicago Partners",
        subtitle: "Expanding our horizons",
        image: "https://images.pexels.com/photos/1334607/pexels-photo-1334607.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        heading: "Kenyan family",
        body: "Early this month, Astrobie became stronger by the integration of new special members from Kenya, a country in east Africa. Our goal has been always improving humans' lives by getting stronger together.",
    },
    {
        id: 3,
        title: "Astrobie in Uriangato",
        subtitle: "ITSUR Became Partner",
        image: "./images/itsur.JPG",
        heading: "Uriangato, Mexico",
        body: "Early this month, Astrobie became stronger by the integration of new special members from Mexico, the Instituto del Sur de Guanajuato had agreed to collaborate on Astrobie Academy.",
    },
    {
        id: 4,
        title: "Astrobie in Africa",
        subtitle: "Expanding our horizons",
        image: "https://images.pexels.com/photos/1667850/pexels-photo-1667850.jpeg",
        heading: "Kenyan family",
        body: "Early this month, Astrobie became stronger by the integration of new special members from Kenya, a country in east Africa. Our goal has been always improving humans' lives by getting stronger together.",
    },
    {
        id: 5,
        title: "Astrobie in Africa",
        subtitle: "Expanding our horizons",
        image: "https://images.pexels.com/photos/1667850/pexels-photo-1667850.jpeg",
        heading: "Kenyan family",
        body: "Early this month, Astrobie became stronger by the integration of new special members from Kenya, a country in east Africa. Our goal has been always improving humans' lives by getting stronger together.",
    },
]

export const NewsContentData = [
    {
        id: 1,
        title: "A New Member of Our Initiative: The Instituto Tecnologico del Sur de Guanajuato (ITSUR) joins Astrobie Academy initiative.",
        quote: "The fact that my work at Astrobie Academy counts towards my degree is unvaluable",
        credits: "Jose Perez - Senior ITSUR Student",
        image: "",
        bodyone: "",
        bodytwo: "",
    },
]