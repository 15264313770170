import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

export default function Complete() {
  const [time, setTime] = useState(30);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [time]);

  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <p className="text-2xl font-semibold ">We are all set!</p>
      <div className="grid justify-items-stretch">
        <p>
          Thank you for your interest in being part of our mission. We are
          delighted to gather amazing individuals who are committed to shaping
          the world and transforming people's lives through education.
        </p>
        <p class="font-semibold pt-3">What's Next?</p>
        <p className="mb-5">
          One of our agents will reach out to you in the next couple of days, so
          please expect an email from us. This person will guide you through the
          process of becoming an Astrobie Academy partner.
        </p>

        {time <= 0 ? (
          <Navigate to="/" replace={true} />
        ) : (
          <label className="mt-5 font-light justify-self-end">
            {" "}
            You will be redirected home in {time} seconds
          </label>
        )}
      </div>
    </div>
  );
}
