import React from 'react';
import { Check, Cancel } from "../resources/Icons";

function Hosting() {
    return (
        <section name='solution' className='bg-[#FDFFFC] px-6 m-auto text-[#1D1D1D] w-[100%] flex items-center justify-center'>
            <div className='max-w-[1090px] mt-20 mb-24 md:mt-24 md:mb-24 items-center justify-center'>
                <div className="text-4xl font-semibold">
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#1D1D1D] to-[#1D1D1D]">
                        Hosting
                    </span>
                </div>
                <div className="mt-0 m-auto -space-y-4 items-center justify-center md:flex md:space-y-0 md:-space-x-4 xl:w-10/12">
                    <div className="relative z-10 -mx-4 group md:w-6/12 md:mx-0 lg:w-5/12">
                        <div aria-hidden="true" className="absolute top-0 w-full h-full rounded-2xl bg-[#fdfffc] shadow-xl"></div>
                        <div className="relative p-6 space-y-6 lg:p-8">
                            <h3 className="text-3xl text-[#1D1D1D] font-semibold text-center">Save Up To</h3>
                            <div>
                                <div className="relative flex justify-around">
                                    <div className="flex items-end text-[#1D64C1]">
                                        <span className="text-8xl font-bold leading-0">60</span>
                                        <div className="pb-2">
                                            <span className="block text-2xl font-semibold">%</span>
                                            <span className="block text-[#1D1D1D] text-xl font-bold">Off</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul className="w-max space-y-4 py-6 m-auto text-[#1D1D1D]">
                                <li className="space-x-2 flex items-center">
                                    <Check/>
                                    <span>Business email plans</span>
                                </li>
                                <li className="space-x-2 flex items-center">
                                    <Check/>
                                    <span>Domain name registration</span>
                                </li>
                                <li className="space-x-2 flex items-center">
                                    <Check/>
                                    <span>Hosting services</span>
                                </li>
                                <li className='space-x-2 flex items-center'>
                                    <span className='text-xs'>Discount rates vary depending on the customer</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="relative group md:w-6/12 lg:w-7/12">
                        <div aria-hidden="true" class="absolute top-0 w-full h-full rounded-2xl bg-[#FDFFFC] shadow-lg"></div>
                        <div className="relative p-6 pt-16 md:p-8 md:pl-12 md:rounded-r-2xl lg:pl-20 lg:p-16">
                            <p className='text-2xl font-semibold'>Advantages</p>
                            <ul className="space-y-4 py-6 text-gray-600">
                                <li className="space-x-2 flex items-center">
                                    <Check/>
                                    <span>Save extra bucks when using Astrobie services</span>
                                </li>
                                <li className="space-x-2 flex items-center">
                                    <Check/>
                                    <span>Get peace of mind knowing we are backing you up</span>
                                </li>
                                <li className="space-x-2 flex items-center">
                                    <Check/>
                                    <span>Only pay for what you need.</span>
                                </li>
                                <li className="space-x-2 flex items-center">
                                    <Check/>
                                    <span>Easy-to-understand price breakdown</span>
                                </li>
                            </ul>
                            <p className="text-gray-700 text-xs">Note: Astrobie LLC does not offer hosting services directly. We partner with third-party hosting providers to offer our clients additional savings when they host their websites through our platform. Astrobie LLC will not manage your website unless requested by the customer.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hosting
