import React from 'react'
import { Input, Checkbox, Select, SelectItem, Button } from "@nextui-org/react";
import MyTextArea from './MyTextArea';
import { closed_eye } from '../resources/Icons';
import { open_eye } from '../resources/Icons';

function NovaForm() {

  const pronouns = [
    {key: "He/Him", label: "He/Him"},
    {key: "She/Her", label: "She/Her"},
    {key: "They/Them", label: "They/Them"},
  ]
  const genders = [
    {key: "Male", label: "Male"},
    {key: "Female", label: "Female"},
    {key: "Non-binary", label: "Non-binary"},
    {key: "No", label: "I prefer not to say"}
  ]

  const [isVisible, setIsVisible] = React.useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <section className='bg-[#F3F4F5] text-[#1D1D1D] w-full px-6 md:px-0 flex items-center justify-center'>
      <div className='bg-[#FDFFFC] max-w-[900px] lg:w-[900px] px-6 md:px-20 py-10 md:py-20 shadow-xl my-20 rounded-2xl'>
        <div className="text-4xl font-semibold mb-14">
          <span className="text-[#1D1D1D]">Create Your <span className="text-[#1d64c1]">Nova</span> Profile</span>
        </div>
        <div className=''>
          <p className='text-[#1d1d1d8e] text-lg mb-8'>Personal details:</p>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10'>
            <Input type="text" label="First Name" isRequired/>
            <Input type="text" label="Middle Name (optional)"/>
            <Input type="text" label="Last Name" isRequired/>
            <Select
              label="Gender"
              placeholder="Select your gender"
              className="max-w-lg"
            >
              {genders.map((gender) => (
                <SelectItem key={gender.key}>
                  {gender.label}
                </SelectItem>
              ))}
            </Select>
            <Select
              label="Pronouns"
              placeholder="Select your pronouns"
              className="max-w-lg"
            >
              {pronouns.map((pronoun) => (
                <SelectItem key={pronoun.key}>
                  {pronoun.label}
                </SelectItem>
              ))}
            </Select>
            <Input type="text" label="Title" isRequired/>
            <Input type="text" label="Middle Name (optional)"/>
            <Input type="text" label="Last Name" isRequired/>
            <Input type="number" label="Age" isRequired/>
          </div>
          

          <p className='text-[#1d1d1d8e] text-lg mt-16 mb-8'>Login Information:</p>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10'>
            
            <Input type="email" label="Email" isRequired/>
            <Input
              label="Password"
              variant="bordered"
              placeholder="Enter your password"
              endContent={
                <button className="focus:outline-none" type="button" onClick={toggleVisibility} aria-label="toggle password visibility">
                  {isVisible ? (
                    <open_eye className="text-2xl pointer-events-none" />
                  ) : (
                    <closed_eye className="text-2xl pointer-events-none" />
                  )}
                </button>
              }
              type={isVisible ? "text" : "password"}
              className="max-w-xs"
            />
            <Input
              label="Password"
              variant="bordered"
              placeholder="Enter your password"
              endContent={
                <button className="focus:outline-none" type="button" onClick={toggleVisibility} aria-label="toggle password visibility">
                  {isVisible ? (
                    <open_eye className="text-2xl pointer-events-none" />
                  ) : (
                    <closed_eye className="text-2xl pointer-events-none" />
                  )}
                </button>
              }
              type={isVisible ? "text" : "password"}
              className="max-w-xs"
            />
          </div>
          
          <p className='text-[#1d1d1d8e] text-lg mt-16 mb-8'>Biography</p> 
          <p className='text-[#1d1d1d8e] mb-3 text-sm'>This is your profile's description, feel free to summarize anything about yourself</p>
          <div className='grid grid-cols-1 gap-6 md:gap-10'>
            <MyTextArea className="mb-2"/>
          </div>


          <p className='text-[#1d1d1d8e] text-lg mb-2 mt-16'>Social Applications:</p>
          <p className='text-[#1d1d1d8e] text-xs mb-8'>Insert you share link for your social media profiles</p>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10'>
            <Input type="text" label="Facebook Link"/>
            <Input type="text" label="LinkedIn Link"/>
            <Input type="text" label="Instagram Link"/>
            <Input type="text" label="Snapchat Link"/>
            <Input type="text" label="Discord Link"/>
            <Input type="text" label="X Link"/>
            <Input type="text" label="Discord Link"/>
            <Input type="text" label="WhatsApp Link"/>
            <Input type="text" label="GitHub Link"/>
            <Input type="text" label="TikTok Link"/>
            <Input type="text" label="YouTube Link"/>
            <Input type="text" label="Telegram Link"/>
            <Input type="text" label="Twitch Link"/>
          </div>



          <p className='text-[#1d1d1d8e] text-lg mb-2 mt-16'>Experience:</p>
          <p className='text-[#1d1d1d8e] text-xs mb-8'>List some of your job experience</p>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10'>
            <Input type="text" label="Company/Institution" isRequired/>
            <Input type="text" label="Year you started" isRequired/>
            <Input type="text" label="Year you ended" isRequired/>
            <Input type="text" label="Title at that job" isRequired/>
            <Input type="text" label="Color" isRequired/>
            <div className='col-span-2'>
              <MyTextArea/>
              <p className='text-xs text-[#1d1d1d8e] pl-1 mt-1'>Job description</p>
            </div>
          </div>



          <p className='text-[#1d1d1d8e] text-lg mb-2 mt-16'>Education:</p>
          <p className='text-[#1d1d1d8e] text-xs mb-8'>List some of your education journey</p>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10'>
            <Input type="text" label="School's Name" isRequired/>
            <Input type="text" label="Year you started" isRequired/>
            <Input type="text" label="Year you ended" isRequired/>
            <Input type="text" label="Major" isRequired/>
            <Input type="text" label="GPA" isRequired/>
            <Input type="text" label="Color" isRequired/>
            <div className='col-span-2'>
              <MyTextArea/>
              <p className='text-xs text-[#1d1d1d8e] pl-1 mt-1'>Degree's description</p>
            </div>
          </div>
          


          <div className='mt-20 grid grid-cols-1 gap-3'>
            <Checkbox defaultSelected size='sm' radius="full">I hereby authorize Astrobie LLC to use my information to create a Nova profile and account</Checkbox>
            <Checkbox defaultSelected size="sm" radius="full">I am 18 or older</Checkbox>
            <Checkbox defaultSelected size='sm' radius="full">I accept the terms & conditions, and certify I read the privacy policy</Checkbox>
          </div>
          <div className='flex items-center justify-center mt-12'>
            <Button className="rounded-full bg-[#1d64c1] text-[#fdfffc]">Create</Button>
          </div>
        </div>

        
      </div>
    </section>
  )
}

export default NovaForm