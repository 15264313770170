import React from 'react'

function WAMain() {
    return (
        <section className='bg-[#FDFFFC] px-6 m-auto text-[#1D1D1D] w-[100%] flex items-center justify-center'>
            <div className='max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24'>
                <p className='text-4xl'>The place where you'll get your <span className='text-[#1D64C1]'>website</span> done</p>
            </div>
        </section>
    )
}

export default WAMain