import React from 'react'

function AAWhy() {
    return (
        <section className='bg-[#ffa76428] text-[#1D1D1D] px-6 flex items-center justify-center m-auto'>
            <div className='mt-20 mb-20 md:mt-24 md:mb-24 max-w-[1044px] mx-auto'>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
                    <div>
                        <p className="text-4xl lg:text-6xl font-semibold">
                            <span className='text-[#ffa764]'>Education</span> for Communities In Need</p>
                        <p className='mt-4'>Customized content tailored specifically for individuals without access to conventional education.</p>
                    </div>
                    <div>

                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default AAWhy