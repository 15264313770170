
import React, { useEffect, useState, useRef } from "react";
import { FiCheck } from "react-icons/fi";


const Stepper = ({ steps, currentStep }) => {

    const [newStep, setNewStep] = useState([]);
    const stepRef = useRef();

    const updateStep = (stepNumber, steps) => {
        const newSteps = [...steps]
        let count = 0;

        while (count < newSteps.length) {
            //current step
            if (count === stepNumber) {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: true,
                    selected: true,
                    completed: true,
                };
                count++;
            }
            // step completed
            else if (count < stepNumber) {
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: false,
                    selected: true,
                    completed: true,
                };
                count++
            } else {
                //step pending
                newSteps[count] = {
                    ...newSteps[count],
                    highlighted: false,
                    selected: false,
                    completed: false,
                };
                count++;
            }

        }
        return newSteps;
    };

    useEffect(() => {
        //crete objetc
        const stepsState = steps.map((step, index) =>
            Object.assign({},
                {
                    description: step,
                    completed: false,
                    highlighted: index === 0 ? true : false,
                    selected: index === 0 ? true : false,


                }
            )
        );

        stepRef.current = stepsState;
        const current = updateStep(currentStep - 1, stepRef.current)
        setNewStep(current);
    }, [steps, currentStep]);

    const displaySteps = newStep.map((step, index) => {
        return (
            <div key={index} className={index !== newStep.length - 1 ? "w-full flex items-center px-auto" : "flex items-center"}>
                 {/* Desktop view */}
                <div className="relative flex flex-col items-center text-[#1d1d1d] hidden md:flex">
                    <div className="flex flex-col-reverse items-center">
                        <div className={`rounded-full transition duration-500 ease-in-out border-2 h-12 w-12 flex items-center justify-center py-3 ${step.selected ? "bg-[#1D65C1] text-[#FDFFFC] font-bold border-[#1D65C1]" : ""}`}>
                            {/*Numbers*/}
                            {step.completed ? (<span className="text-[#FDFFFC] font-bold text-xl"><FiCheck /></span>) : ("")}
                        </div>
                        <div className={`md:order-last absolute top-0 text-center mt-16 text-xs font-medium ${step.highlighted ? "text-[#1d1d1d]" : "text-[#403F4A]"}`}>
                            {/*Description*/}
                            {step.selected ? `${step.description} ` : `${step.description} `}
                        </div>

                    </div>
                </div>

                {/* Mobile view */}
                <div className="relative flex flex-col items-center text-[#1d1d1d] md:hidden">
                    <div className="flex flex-col items-center">

                        <div className={`rounded-full transition duration-500 ease-in-out border-1 h-6 w-6 flex items-center justify-center py-3 ${step.selected ? "bg-[#1D65C1] text-[#FDFFFC] font-bold border-[#1D65C1]" : ""}`}>
                            {/*Numbers*/}
                            {step.completed ? (<span className="text-[#FDFFFC] font-bold text-xl"><FiCheck /></span>) : ("")}
                        </div>
                        <div className={`md:order-last absolute top-0 text-center mt-8 text-xs font-medium ${step.highlighted ? "text-[#1d1d1d]" : "text-[#403F4A]"}`}>
                            {/*Description*/}
                            {index===currentStep ? `${step.description} ` : ` `}
                        </div>
                        
                    </div>
                </div>

                <div className={`flex-auto border-t w-full transition duration-500 ease-in-out ${step.selected ? "border-medium" : "" } ${step.selected ? "border-[#1D65C1]" : "border-[#acabab]"}`}>
                    {/*Line*/}
                </div>
            </div>
        );
    });

    return (
        <div className="flex items-center justify-around gap-0 p-4 mx-4 mb-10">
            {displaySteps}
        </div>
    );
};


export default Stepper;
