import React from 'react';
import {Card, CardHeader, CardFooter, Image, Button} from "@nextui-org/react";
import Spline from '@splinetool/react-spline';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';


// import required modules
import {Pagination, FreeMode} from 'swiper/modules';

export default function Design(){
    return (
        <section name="design" className='p-0 m-0 py-36 bg-[#EBEDEA]  flex-col items-center justify-center'>
            <div className=" px-6 text-[#1D1D1D] mx-auto flex-column content-center static gap-8 max-w-[1144px]">
                <div >
                    <h1 className='text-3xl md:text-5xl'>Instant connectivity.</h1>
                    <h5 className='text-xl md:text-3xl'>Fast. Reliable. Secure.</h5>
                </div>
                <div className='my-10'>
                    <Spline scene="https://prod.spline.design/xssQMdbF1y2kPJYh/scene.splinecode" />
                </div>
            </div>
        </section>
    )

}