import React from 'react'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import {Pagination, FreeMode} from 'swiper/modules';
import {AssistanceData} from "./data";
import CatalogCard from "./CatalogCard";



function WebsiteType() {
    return (
        <section name='solution' className='bg-[#FDFFFC] text-[#1D1D1D] py-2 w-[100%] flex-col items-center justify-center'>
            <div className='mt-20 mb-24 md:mt-24 md:mb-24 px-6 mx-auto flex-column content-center static gap-8 max-w-[1144px]'>
                <div >
                    <span className="text-4xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-[#1D1D1D] to-[#1D1D1D]">
                        Website Catalog
                    </span>
                    <p className='text-xl text-[#1D1D1D] mb-8'>Get the website you need</p>
                </div>
                <div>
                    <Swiper
                        style={{
                            "--swiper-pagination-color": "#1D65C1",
                            "--swiper-pagination-bullet-inactive-color": "#989997",
                            "--swiper-pagination-bullet-inactive-opacity": "1",
                            "--swiper-pagination-bullet-size": "8px",
                            "--swiper-pagination-bullet-horizontal-gap": "6px",
                        }}
                        modules={[FreeMode, Pagination]}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 20
                            },
                            400: {
                                slidesPerView: 1.3,
                                spaceBetween: 40,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            735: {
                                slidesPerView: 2.5,
                                spaceBetween: 60,
                            },
                            1024: {
                                slidesPerView: 3.3,
                                spaceBetween: 10,
                            }
                        }}

                        freeMode={true}
                        pagination={{
                            clickable: true
                        }}
                        className='max-w-[1144px]'
                    >
                        {AssistanceData.map((item) => (
                            <SwiperSlide key={item.id}>
                                <CatalogCard id={item.title} classNames={{title: 'text-' + item.color }} title={item.title} content={item.content} imageForModal={item.imageForModal} description={item.description} />
                            </SwiperSlide>
                        ))}

                    </Swiper>
                </div>
            </div>
        </section>
        
    )
}

export default WebsiteType