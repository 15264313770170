import React from 'react';
import { useForm } from 'react-hook-form';
import { Input, Select, SelectItem, Button, Textarea } from "@nextui-org/react";
import { motion } from "framer-motion";
import Toast from './Alert';

import { collection, addDoc } from "firebase/firestore";
import { db } from '../firebase/config';


function CustomerSupport() {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        watch,
        setValue,
        trigger,
        reset
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            firstName: "",
            lastName: "",
            gender: "", 
            country: "", 
            email: "",
            confirmEmail: "",
            problem: "",
            explanation: "",

        }
    });

    const onSubmit = async (data) => {
        // Handle form submission here
        console.log("Form submitted!", data);
        try {
            // Añadir los datos a Firestore
            const docRef = await addDoc(collection(db, "customer_support"), data);
            Toast.fire({
                icon: "success",
                title: "Your report has been registered, we will check it as son as posible"
            });
            reset();
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    };

    const email = watch("email");
    const confirmEmail = watch("confirmEmail");

    return (
        <section className="px-6 m-auto text-[#1D1D1D] w-full flex items-center justify-center">
            <div className="w-full max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24 grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className='order-last flex items-center justify-center w-full md:h-96 h-40'>
                    {/* <img
                        className="object-scale-down w-full h-full mx-auto rounded-md"
                        src="/images/astrobie_icon.png"
                        alt=""
                    /> */}
                    <motion.img
                        className="object-contain w-full max-w-xs h-full max-h-64 mx-auto rounded-md"
                        src="/images/astrobie_icon.png" 
                        alt=""
                        animate={{
                            y: [0, -20, 0],
                        }}
                        transition={{
                            duration: 2,
                            ease: "linear",
                            repeat: Infinity,
                            repeatType: "loop",
                        }}
                    />
                </div>

                <div className="flex items-center justify-center">
                    <div className="max-w-2xl mx-auto">
                        <div className="text-4xl font-semibold">
                            <span className="text-[#1D1D1D]">Customer Support</span>
                        </div>
                        <p className="text-xl text-[#1D1D1D] mb-8">Got a problem? Let us now.</p>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="grid xl:grid-cols-2 xl:gap-6">
                                <div className="relative z-0 mb-6 w-full group">
                                    <Input
                                        {...register("firstName", {
                                            required: "First name is required",
                                            pattern: {
                                                value: /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s'-]{1,50}$/,
                                                message: "Invalid first name"
                                            }
                                        })}
                                        isInvalid={!!errors.firstName}
                                        color={errors.firstName ? "danger" : ""}
                                        errorMessage={errors.firstName?.message}
                                        type="text"
                                        label="First Name"
                                        className="max-w-xs"
                                        isRequired
                                    />
                                </div>
                                <div className="relative z-0 mb-6 w-full group">
                                    <Input
                                        {...register("lastName", {
                                            required: "Last name is required",
                                            pattern: {
                                                value: /^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s'-]{1,50}$/,
                                                message: "Invalid last name"
                                            }
                                        })}
                                        isInvalid={!!errors.lastName}
                                        color={errors.lastName ? "danger" : ""}
                                        errorMessage={errors.lastName?.message}
                                        type="text"
                                        label="Last name"
                                        isRequired
                                    />
                                </div>
                            </div>

                            <div className="grid xl:grid-cols-2 xl:gap-6">
                                <div className="relative z-0 mb-6 w-full group">
                                    <Select
                                        {...register("gender")}
                                        label="Choose a gender"
                                        isInvalid={!!errors.gender}
                                        errorMessage={errors.gender?.message}
                                        isRequired
                                    >
                                        <SelectItem key={"M"}>Male</SelectItem>
                                        <SelectItem key={"F"}>Female</SelectItem>
                                        <SelectItem key={"NB"}>Non-binary</SelectItem>
                                        <SelectItem key={"NA"}>
                                            I prefer not to specify
                                        </SelectItem>
                                    </Select>
                                </div>
                                <div className="relative z-0 mb-6 w-full group">
                                    <Select
                                        {...register("country")}
                                        label="Choose a country"
                                        isInvalid={!!errors.country}
                                        errorMessage={errors.country?.message}
                                        isRequired
                                    >
                                        <SelectItem key={"US"}>United States</SelectItem>
                                    </Select>
                                </div>
                            </div>

                            <div className="relative z-0 mb-6 w-full group">
                                <Input
                                    {...register("email", {
                                        required: "Email is required",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: "Invalid email address"
                                        }
                                    })}
                                    isInvalid={!!errors.email}
                                    color={errors.email ? "danger" : ""}
                                    errorMessage={errors.email?.message}
                                    type="email"
                                    label="Email address"
                                    isRequired
                                />
                            </div>

                            <div className="relative z-0 mb-6 w-full group">
                                <Input
                                    {...register("confirmEmail", {
                                        required: "Confirm email is required",
                                        validate: value => value === email || "Emails do not match"
                                    })}
                                    isInvalid={!!errors.confirmEmail}
                                    color={errors.confirmEmail ? "danger" : ""}
                                    errorMessage={errors.confirmEmail?.message}
                                    type="email"
                                    label="Confirm email address"
                                    isRequired
                                />
                            </div >

                            <div className="relative z-0 mb-6 w-full group">
                                <Input
                                    {...register("problem", {
                                        required: "Problem description is required",
                                    })}
                                    isInvalid={!!errors.problem}
                                    color={errors.problem ? "danger" : ""}
                                    errorMessage={errors.problem?.message}
                                    type="text"
                                    label="Problem"
                                    isRequired
                                />
                            </div>

                            <div className="relative z-0 mb-6 w-full group">
                                <Textarea
                                    {...register("explanation", {
                                        required: "Explanation is required",
                                    })}
                                    isInvalid={!!errors.explanation}
                                    color={errors.explanation ? "danger" : ""}
                                    type="text"
                                    label="Describe your problem"
                                    placeholder="What happend?"
                                    isRequired
                                />
                            </div>
                            <p className='text-xs mb-6 text-[#1d1d1d]'>We will never share or sell your information with anyone else</p>
                            <Button
                                className="text-[#FDFFFC] bg-[#1D64C1] rounded-full"
                                isDisabled={!isValid}
                                type='submit'
                            >
                                Send
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CustomerSupport;

{/*        {...register("confirmEmail", {
                                        required: "Confirm email is required",
                                        validate: value => value === email || "Emails do not match"
                                    })}
                                    */}