import React from "react";
import { Button, Card, CardBody, CardHeader, CardFooter, Image } from "@nextui-org/react";
import BasicModal from "../BasicModal";
import { Link } from "react-router-dom";

function Options() {
    const cardsData = [
        {
            tagline: "Be part of our family",
            colorTagline: "text-[#5AB489]",
            title: "For small businesses",
            subtitle: "Astrobie Academy is the result and legacy of amazing individuals",
            image: "/images/For_Volunteers.svg",
            modalHeader: "Rising by lifting others",
            modalBody:
                "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vero alias illo, quisquam cum porro et, repudiandae aliquam nobis inventore facere eligendi vel recusandae culpa eos illum nostrum autem voluptates laboriosam!",
            modalLink: "/AvailableSoon",
        },
        {
            tagline: "Be part of our family",
            colorTagline: "text-[#6650D5]",
            title: "For companies",
            subtitle: "Astrobie Academy is the result and legacy of amazing individuals",
            image: "/images/For_Colleges_and_Universities.svg",
            modalHeader: "Rising by lifting others",
            modalBody:
                "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vero alias illo, quisquam cum porro et, repudiandae aliquam nobis inventore facere eligendi vel recusandae culpa eos illum nostrum autem voluptates laboriosam!",
            modalLink: "/enterprise",
        },
    ];
    return (
        <section className='bg-[#FDFFFC] px-6 m-auto text-[#1D1D1D] w-[100%] flex items-center justify-center'>
            <div className='max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24'>
                <div className="text-4xl font-semibold">
                    <span className="text-[#1D1D1D]">
                        Options
                    </span>
                </div>
                <p className='text-xl text-[#1D1D1D] mb-8'>Profits</p>

                <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                    {cardsData.map((card, index) => (
                        <Card key={index} className="bg-[#F4F6F3] lg:w-[400px] xl:w-[550px] min-w-[300px] h-[300px] mt-5 shadow-none">
                            <CardHeader className='flex-col items-start'>
                                <p className={`text-sm md:text-medium font-semibold uppercase ${card.colorTagline}`}>{card.tagline}</p>
                                <p className='text-xl md:text-2xl xl:text-3xl font-semibold'>{card.title}</p>
                                <p className='text-xs font-normal'>{card.subtitle}</p>
                            </CardHeader>
                            <CardBody className="overflow-visible flex justify-end">
                                <Image
                                    removeWrapper
                                    alt={card.imageAlt}
                                    className="z-10 w-[130px] absolute object-cover"
                                    src={card.image} // Here you can assign an image URL if you have one.
                                />
                            </CardBody>
                            <CardFooter className="bottom-0 z-10 h-16 justify-end flex">
                                <BasicModal
                                    size="xl"
                                    header={() => <h4>{card.modalHeader}</h4>}
                                    body={() => <p>{card.modalBody}</p>}
                                    footer={() => (
                                        <Link to={card.modalLink}>
                                            <Button className="rounded-full bg-[#1D65C1] text-[#FDFFFC] mx-2">
                                                Register
                                            </Button>
                                        </Link>
                                    )}
                                    button={(handleClick) => (
                                        <Button
                                            isIconOnly
                                            className="bg-[#1D65C1] hover:bg-[#2b51cc] rounded-full text-[#FDFFFC] text-lg"
                                            onClick={handleClick}
                                        >
                                            &#43;
                                        </Button>
                                    )}
                                    classNames={{ all: "" }}
                                />
                            </CardFooter>
                        </Card>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Options