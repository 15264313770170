import React, { useContext, useState } from "react";
import { Input, Button } from "@nextui-org/react";
import { useForm } from "react-hook-form";
import { StepperContext } from "../Context/StepperContext";

export default function BussinessInfo({ handleClick, currentStep, steps }) {
  const { formData, updateFormData } = useContext(StepperContext);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(formData);
    updateFormData({ ...formData, [name]: value });
  };
  //const { register, handleSubmit, formState: { errors } } = useForm();

  // Función para validar los campos del formulario
  const validateForm = () => {

    //put here the messages for the inputs with key like input id or name
    const validationRules = {
      businessName: 'Business name is required',
      address: 'Business address  is required',
      type: 'Type is required',
      state: 'State is required',
      companyPhone: 'Phone number is required',
      zip: 'Zip code is required'

    };

    const newErrors = {};

    if (!(/^[0-9]{10}$/).test(formData.companyPhone))
      newErrors.companyPhone = "Number format is incorrect";


    if (!(/^\d{5}(?:[-\s]\d{4})?$/).test(formData.zip))
      newErrors.zip = "Invalid zip format";

    for (const [field, errorMessage] of Object.entries(validationRules)) {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
      }

    }


    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleClick("next");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="my-5">Business/Organization information</p>
      <div className="flex flex-col gap-4">

        <div className="grid md:grid-cols-2 gap-5">
          {/**Business Info */}
          <Input
            type="Text"
            label="Business/Organization's official name"
            onChange={handleChange}
            id="businessName"
            name="businessName"
            value={formData.businessName}
            isRequired
            isInvalid={errors.businessName ? true : false}
            errorMessage={errors.businessName}
          />
          {/**Type */}
          <Input
            type="Text"
            label="Type"
            labelPlacement="outside-left"
            size="lg"
            className="w-full items-center"
            onChange={handleChange}
            id="type"
            name="type"
            value={formData.type}
            isRequired
            isInvalid={errors.type ? true : false}
            errorMessage={errors.type}

          />

        </div>

        <div className="grid md:grid-cols-2 gap-5">
          {/**Address */}
          <Input
            type="Text"
            label="Address"
            onChange={handleChange}
            id="address"
            name="address"
            value={formData.address}
            isRequired
            isInvalid={errors.address ? true : false}
            errorMessage={errors.address}
          />
          {/**State */}
          <Input
            type="Text"
            label="State"
            labelPlacement="outside-left"
            size="lg"
            onChange={handleChange}
            id="state"
            className="w-full items-center"
            name="state"
            value={formData.state}
            isRequired
            isInvalid={errors.state ? true : false}
            errorMessage={errors.state}
          />

        </div>

        <div className="grid md:grid-cols-2 gap-5">
          {/**Address 2nd  */}
          <Input
            type="Text"
            label="Address 2 (opt, unit, suit)"
            onChange={handleChange}
            id="address2"
            name="address2"
            value={formData.address2}

          />
          {/**ZIP Code */}
          <Input
            type="Text"
            label="ZIP Code"
            onChange={handleChange}
            id="zip"
            name="zip"
            value={formData.zip}
            isRequired
            isInvalid={errors.zip ? true : false}
            errorMessage={errors.zip}

          />
        </div>

        <div className="grid md:grid-cols-2 gap-5">
          {/**Phone */}
          <Input
            type="Text"
            label="Phone Number"
            onChange={handleChange}
            id="companyPhone"
            name="companyPhone"
            value={formData.companyPhone}
            isRequired
            isInvalid={errors.companyPhone ? true : false}
            errorMessage={errors.companyPhone}

          />
          {/**website */}
          <Input
            type="Text"
            label="Website URL (if applicable)"
            onChange={handleChange}
            name="website"
            id="website"
            value={formData.website}
          />

        </div>

        <div className="container flex justify-between mt-12 mb-4">
          <Button
            onClick={() => handleClick()}
            className={`bg-[#FDFFFC] text-[#1D1D1D] px-4 py-2 rounded-full 
font-semibold cursor-pointer border-1 border-[#1D1D1D] hover:text-[#FDFFFC] hover:bg-[#1D1D1D] transition duration-200 ease-in-out ${currentStep === 1
                ? "opacity-50 cursor-not-allowed"
                : "cursor-pointer"
              }`}
          >
            Back
          </Button>

          <Button
            type="submit"
            className="bg-[#1D65C1] px-4 py-2 rounded-full 
font-semibold  border-1 border-[#1D65C1] text-[#FDFFFC] cursor-pointer"
          >
            {currentStep === steps.length ? "Confirm" : "Next"}
          </Button>
        </div>
      </div>
    </form>
  );
}
