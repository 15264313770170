import React from "react";
import {twMerge} from "tailwind-merge";
import clsx from "clsx";
export default function AstrobieLogo(props) {


        const className = twMerge(clsx("min-w-[100px]"), props?.className);

        return (
        <svg className={className} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 1920 412.5">
                <defs>
                </defs>
                <path className="cls-1"
                      d="M253.47,343.13c-25-.98-41.69-10.98-52.77-30.07-27.4-47.23-54.68-94.52-82.02-141.79-3-5.18-6-10.35-9.03-15.51-19.13-32.48.08-75.18,36.97-83.06,24.34-5.19,49.47,6.7,61.7,28.02,29.14,50.81,58.53,101.48,87.88,152.17,4.2,7.25,8.08,14.5,9.81,22.85,5.56,26.75-10.18,54.81-36.35,63.71-6.06,2.06-12.56,2.87-16.19,3.67Z"/>
                <path className="cls-1"
                      d="M1392.37,341.33h-34.81V54.93h34.8v113.41c3.93-4.88,6.94-9.1,10.43-12.89,10.68-11.61,23.61-19.69,38.68-24.55,10.56-3.41,21.4-4.85,32.35-4.78,11.6.07,22.95,2.38,33.92,6.34,30.83,11.15,49.67,33.55,59.67,63.92,6.27,19.03,6.93,38.74,4.13,58.4-3.84,26.97-15.61,50.11-36.63,67.82-20.48,17.24-44.47,23.97-71.13,21.9-17.48-1.35-33.47-6.63-47.76-16.82-7.67-5.47-14.52-11.79-19.71-19.75-.99-1.51-2.06-2.97-3.93-4.24v37.65ZM1537.44,235.03c-.51-5.7-.81-11.43-1.62-17.09-.54-3.78-1.68-7.5-2.77-11.18-11.72-39.56-49.82-58.27-87.55-48.21-32.55,8.68-50.29,36.23-52.96,67.83-1.09,12.84-.18,25.66,4.02,37.99,6.4,18.81,17.85,33.51,35.78,42.68,14.73,7.54,30.35,9.07,46.49,6.18,19.04-3.41,33.79-13.43,44.55-29.36,9.95-14.74,13.79-31.24,14.07-48.84Z"/>
                <path className="cls-1"
                      d="M575.62,341.55h-21.83c-3.62,0-7.27-.25-10.86.08-3.13.28-4.37-1.08-5.32-3.79-6.27-17.68-12.7-35.3-18.97-52.98-.91-2.57-2.07-3.66-5.02-3.65-36.99.11-73.97.1-110.96.02-2.77,0-4.13.73-5.12,3.52-6.33,17.92-12.87,35.77-19.47,53.59-.47,1.26-1.97,3.04-3.03,3.06-11.35.22-22.7.14-34.71.14,2.72-7.59,5.22-14.67,7.8-21.73,23.26-63.83,46.53-127.66,69.8-191.48,6.29-17.26,12.62-34.52,18.8-51.82.86-2.4,1.99-3.24,4.56-3.2,11.37.14,22.74.15,34.11,0,2.47-.03,3.53.9,4.3,3.02,20.29,56.06,40.61,112.11,60.93,168.15,11.26,31.05,22.52,62.1,33.78,93.16.41,1.12.71,2.28,1.22,3.93ZM408.64,253.25h98.75c-16.37-45.7-32.56-90.87-48.74-136.05-.43.03-.87.05-1.3.08-16.16,45.11-32.32,90.23-48.71,135.97Z"/>
                <path className="cls-1"
                      d="M1738.25,248.36c.72,15.39,4.71,29.01,13.62,40.97,9.79,13.15,22.9,21.71,38.89,24.63,18.06,3.3,36.02,2.82,52.44-7.19,10.1-6.16,17.62-14.64,22.16-25.54,1.45-3.48,3.24-4.85,7.18-4.73,10.6.33,21.21.11,32.78.11-2.17,5.46-3.77,10.52-6.11,15.21-13.66,27.32-35.64,44.22-65.51,50.39-26.81,5.54-53.12,3.98-77.59-9.35-25.24-13.75-41.36-34.97-49.24-62.59-4.59-16.08-5.35-32.5-3.91-48.9,2.56-29.14,13.62-54.41,36.94-73.27,12.14-9.82,26.09-15.86,41.29-19.21,13.52-2.98,27.2-3.81,40.87-1.97,29.11,3.93,53.32,16.49,70.21,41.27,12.44,18.25,17.17,38.71,17.1,60.47-.02,5.72-.49,11.45-1.05,17.15-.09.93-1.62,1.94-2.7,2.47-.8.4-1.96.08-2.96.08-53.12,0-106.25,0-159.37,0h-5.05ZM1873.46,219.97c-.63-5.23-1-10.05-1.81-14.79-2.85-16.78-11.8-29.61-25.97-38.82-11.42-7.42-24.19-10.1-37.67-10.84-27.85-1.53-54.23,14.85-64,40.87-2.79,7.42-4.15,15.38-6.29,23.59h135.74Z"/>
                <path className="cls-1"
                      d="M1310.71,234.85c-.52,30.07-9.59,56.59-30.7,78.49-12.04,12.49-26.55,21-43.01,26.25-20.73,6.61-41.95,7.4-62.88,2.29-35.1-8.57-59.84-30.06-71.81-64.23-10.61-30.27-10.15-60.97,2.71-90.73,10.07-23.32,26.71-40.58,50.35-50.71,12.9-5.53,26.2-9.05,40.35-9.63,36.48-1.48,67.75,9.34,91.43,38.11,10.62,12.91,17.52,27.75,20.3,44.28,1.44,8.57,2.2,17.25,3.27,25.87ZM1131.14,236.17c-.27,9.87,1,20.36,4.27,30.5,5.74,17.8,16.14,31.96,33.31,40.3,16.76,8.14,34.41,9.82,52.35,5.03,20.49-5.47,35.79-17.79,44.87-37.07,10.56-22.42,10.85-45.75,4.23-69.18-4.9-17.32-15.41-30.79-30.98-40.15-13.34-8.03-27.91-10.46-43.2-9.34-25.83,1.89-44.62,14.63-56.37,37.49-6.68,13-8.73,27.13-8.48,42.42Z"/>
                <path className="cls-1"
                      d="M769.6,191.72h-33.83c-1.95-5.47-3.32-11.08-5.86-16.1-4.38-8.65-11.9-14.04-21.04-16.92-16.49-5.2-32.79-5.02-48.28,3.38-17.4,9.43-18.67,32.37-2.75,44.15,9.63,7.12,20.81,10.57,32.07,13.75,17.24,4.86,34.73,9.02,50.88,16.96,11.79,5.8,21.62,14.05,26.7,26.61,7.72,19.09,5.3,37.16-7.06,53.54-11.16,14.79-26.87,22.56-44.7,25.82-21.12,3.86-42.1,2.81-62.32-5.1-20.51-8.03-35.95-21.33-42.89-42.89-1.5-4.66-2.14-9.6-3.24-14.72,1.46-.18,2.17-.34,2.87-.34,10.12-.02,20.25.05,30.37-.06,2.26-.02,3.51.57,3.87,2.9,2.71,17.82,18.2,28.43,33.88,31.46,11.34,2.19,22.82,2.35,34.03-.95,12.45-3.67,22-10.58,24.74-24.2,2.39-11.92-2.7-21.66-13.72-26.96-13.72-6.6-28.44-10.11-42.98-14.24-13.21-3.75-26.58-7.24-38.62-14.11-11.03-6.28-20.38-14.35-25.61-26.37-8.87-20.36-3.9-44.59,12.26-59.79,10.46-9.84,22.93-15.68,36.86-18.65,12.77-2.72,25.65-3.83,38.62-2.05,22.58,3.11,42.32,11.5,55.47,31.22,6.7,10.04,9.96,21.26,10.26,33.64Z"/>
                <path className="cls-1"
                      d="M864.79,158.12c0,1.67,0,2.66,0,3.65,0,40.8-.03,81.59.06,122.39,0,3.83.46,7.72,1.24,11.47,1.81,8.69,8.28,14.32,17.87,15.58,4.68.61,9.42.88,14.14.99,7.23.16,14.46.04,21.87.04.16.77.38,1.35.39,1.93.02,8.11-.03,16.22.06,24.33.02,2.19-.6,3.14-2.97,3.09-12.99-.26-26.02.18-38.94-.8-7.34-.55-14.79-2.61-21.68-5.29-15.6-6.07-23.1-18.78-25.51-34.65-1.04-6.86-1.26-13.9-1.28-20.86-.11-39.05-.11-78.1.03-117.15.01-3.81-.99-4.99-4.81-4.8-6.48.31-13,0-19.49.13-2.65.06-3.85-.62-3.79-3.54.17-7.23.17-14.47,0-21.71-.07-2.95,1.17-3.57,3.79-3.52,6.87.13,13.75-.06,20.62.09,2.82.06,3.66-.98,3.64-3.71-.1-15.22.03-30.44-.11-45.66-.03-3.28.66-4.64,4.31-4.53,8.99.27,18,.19,26.99.03,2.81-.05,3.64.83,3.62,3.61-.12,15.34.04,30.69-.13,46.03-.03,3.23.74,4.29,4.13,4.25,15.87-.18,31.75,0,47.62-.14,3.17-.03,4.07,1,3.98,4.07-.2,6.98-.21,13.98,0,20.96.1,3.08-1.03,3.79-3.89,3.78-15.75-.11-31.5-.05-47.24-.05-1.34,0-2.68,0-4.52,0Z"/>
                <path className="cls-1"
                      d="M20.08,286.71c-.06-30.91,25.12-56.19,56.05-56.29,30.97-.1,56.67,25.14,56.73,55.72.06,31.4-25.18,56.7-56.51,56.68-31.08-.02-56.21-25.09-56.27-56.11Z"/>
                <path className="cls-1"
                      d="M999.39,162.81c14.68-26.68,38.47-36.43,67.62-37.24.09,1.73.2,2.94.2,4.14.02,8.23,0,16.47,0,24.7,0,7.01-.01,6.73-6.85,7.09-7.21.38-14.5.75-21.55,2.17-20.92,4.21-32.96,17.85-37.52,38.02-1.96,8.66-2.76,17.74-2.84,26.65-.31,36.18-.19,72.36-.04,108.54.02,3.78-.92,4.9-4.78,4.77-8.99-.31-18-.17-26.99-.06-2.4.03-3.54-.68-3.27-3.17.08-.74.01-1.5.01-2.24,0-67.24,0-134.49,0-201.73q0-5,5.15-5c8.37,0,16.75.2,25.12-.09,3.91-.13,4.89,1.2,4.76,4.92-.27,8.1-.09,16.22-.07,24.33,0,1.2.12,2.4.18,3.61.29.2.57.4.86.6Z"/>
                <path className="cls-1"
                      d="M1654.47,341.54c-10.99,0-21.6.05-32.2-.11-.79-.01-1.71-1.49-2.28-2.45-.33-.56-.06-1.46-.06-2.21,0-67.49,0-134.98,0-202.46q0-4.85,4.94-4.85c8.75,0,17.5.08,26.24-.05,2.73-.04,3.67.8,3.67,3.63-.07,68.49-.05,136.97-.06,205.46,0,.85-.13,1.7-.24,3.04Z"/>
                <path className="cls-1"
                      d="M1660.99,70.52c0,13.25-10.36,23.86-23.24,23.82-12.91-.05-23.33-10.61-23.43-23.76-.1-13.34,10.24-23.57,23.75-23.52,13,.05,22.92,10.21,22.92,23.46Z"/>
        </svg>
        )
};

