import React, { useState } from 'react';
import { Textarea } from '@nextui-org/react';

const MyTextArea = () => {
  const maxChars = 300;
  const [text, setText] = useState('');
  const [warning, setWarning] = useState('');

  const handleChange = (event) => {
    const value = event.target.value;
    if (value.length <= maxChars) {
      setText(value);
      setWarning('');
    } else {
      setWarning(`Character limit exceeded! Max ${maxChars} characters allowed.`);
    }
  };

  return (
    <div>
      <Textarea
        isRequired
        value={text}
        onChange={handleChange}
        placeholder="Type here..."
        fullWidth
      />
      {warning && <p style={{ color: 'red' }}>{warning}</p>}
    </div>
  );
};

export default MyTextArea;
