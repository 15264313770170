import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Accordion,
  AccordionItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalContent,
  ModalHeader,
  useDisclosure,
  Input,
  Textarea,
  Checkbox,
} from "@nextui-org/react";
import { useForm } from "react-hook-form";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import Toast from './Alert';

function Faq() {
  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }
    return window.innerWidth <= 767;
  };

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [isSelected, setIsSelected] = React.useState(false);

  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Handle the form submit
  const onSubmit = (data) => {
    //console.log(data);
    const questionRef = collection(db, "faq");
    addDoc(questionRef, data)
      .then((doc) => {
        onOpenChange(false);
        reset();
        Toast.fire({
          icon: "success",
          title: "Good question! Someone will review and answer your question"
        });
      })
      .catch((reason) => {
        Toast.fire({
          icon: "error",
          title: "We have had some problems, please try again."
        });
      })

  };

  return (
    <section
      name="faq"
      className="p-0 m-0 py-36 bg-[#FDFFFC]  flex-col items-center justify-center w-[100%]"
    >
      <div className=" px-6 text-[#1D1D1D] mx-auto flex-column content-center static gap-8 max-w-[1144px]">
        <div className="pl-2">
          <p className="text-4xl font-semibold text-left">
            Frequently Asked Questions
          </p>
        </div>

        <div className="items-center pl-6 pt-10 mb-8">
          <p className="pl-2 text-2xl md:text-xl font-medium text-left">
            Astrobie
          </p>
          <Accordion className="ms-6">
            <AccordionItem
              classNames={{
                title: "font-medium text-md md:text-sm",
                content: "text-[#1d1d1d8e] font-normal text-sm md:text-xs",
              }}
              key="1"
              aria-label="Accordion 1"
              title="What should I do if I encounter issues with the website?"
            >
              <>
                <p>If you ever encounter an issue navigating through the site, please do not hesitate to submit a bug report at{" "}
                  <a
                    href="https://astrobie.com/Support"
                    className="text-[#1D65C1] underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://astrobie.com/support
                  </a>
                </p>
              </>
            </AccordionItem>
            <AccordionItem
              classNames={{
                title: "font-medium text-md md:text-sm",
                content: "text-[#1d1d1d8e] font-normal text-sm md:text-xs",
              }}
              key="2"
              aria-label="Accordion 2"
              title="How can I contact customer support?"
            >
              <>
                <p>If you experience any issues with our services or products, please contact us directly by submitting the form at {" "}
                  <a
                    href="https://astrobie.com/Support"
                    className="text-[#1D65C1] underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://astrobie.com/support.
                  </a>
                  {" "}Our team is committed to addressing your concerns promptly and effectively.
                </p>
              </>
            </AccordionItem>
            <AccordionItem
              classNames={{
                title: "font-medium text-md md:text-sm",
                content: "text-[#1d1d1d8e] font-normal text-sm md:text-xs",
              }}
              key="3"
              aria-label="Accordion 3"
              title="Are the products covered by warranty?"
            >
              <>
                <p>All of our products are covered by a Limited Warranty policy. For more details, please review the warranty status of your products. If you require assistance with a specific case, do not hesitate to contact us directly at{" "}
                  <a
                    href="mailto:customersupport@astrobie.com"
                    className="text-[#1D65C1]"
                  >
                    customersupport@astrobie.com.
                  </a>
                </p>
              </>
            </AccordionItem>
            <AccordionItem
              classNames={{
                title: "font-medium text-md md:text-sm",
                content: "text-[#1d1d1d8e] font-normal text-sm md:text-xs",
              }}
              key="4"
              aria-label="Accordion 4"
              title="Can I request customization for certain products?"
            >
              <>
                <p>Some products give you the option to customize the product while ordering it. However, customization after the product was delivered will not be covered by Astrobie LLC or directly made by Astrobie LLC. You can still ask for a quote by contacting{" "}
                  <a
                    href="mailto:customersupport@astrobie.com"
                    className="text-[#1D65C1]"
                  >
                    customersupport@astrobie.com.
                  </a>
                </p>
              </>
            </AccordionItem>
          </Accordion>
        </div>

        <div className="items-center pl-6 pt-10">
          <p className="pl-2 text-2xl md:text-xl font-medium text-left">
            Astrobie Academy
          </p>
          <Accordion className="ms-6">
            <AccordionItem
              classNames={{
                title: "font-medium text-md md:text-sm",
                content: "text-[#1d1d1d8e] font-normal text-sm md:text-xs",
              }}
              key="1"
              aria-label="Accordion 1"
              title="How do I apply to become a volunteer?"
            >
              <>
                <p>Whether you wish to volunteer as an individual or represent an institution interested in partnering with the Academy initiative, you can complete the volunteering form found in the “Resources” section at{" "}
                  <a
                    href="https://astrobie.com/academy"
                    className="text-[#1D65C1] underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://astrobie.com/academy.
                  </a>
                  {" "}There, you will find an online form for both individual volunteers and institutions; please select the option that best suits your needs.
                </p>
              </>
            </AccordionItem>
            <AccordionItem
              classNames={{
                title: "font-medium text-md md:text-sm",
                content: "text-[#1d1d1d8e] font-normal text-sm md:text-xs",
              }}
              key="2"
              aria-label="Accordion 2"
              title="Can I volunteer remotely, or are all activities in person?"
            >
              <>
                <p>
                  If you reside outside the United States and are interested in becoming a partner or volunteering for this cause, you are welcome to apply for a remote position. Remote roles are typically available for individuals located outside the U.S., and we currently have active members and institutions from various countries. We look forward to your involvement!
                </p>
              </>
            </AccordionItem>
            <AccordionItem
              classNames={{
                title: "font-medium text-md md:text-sm",
                content: "text-[#1d1d1d8e] font-normal text-sm md:text-xs",
              }}
              key="3"
              aria-label="Accordion 3"
              title="Can I support the project without volunteering?"
            >
              <>
                <p>
                  Yes! We will soon introduce a donations channel where anyone can contribute. Institutions are also invited to join as partners, allowing us to develop more courses and content for our learners in Africa, Asia, and Latin America. Your support will make a significant impact on our mission!
                </p>
              </>
            </AccordionItem>
            <AccordionItem
              classNames={{
                title: "font-medium text-md md:text-sm",
                content: "text-[#1d1d1d8e] font-normal text-sm md:text-xs",
              }}
              key="4"
              aria-label="Accordion 4"
              title="Can volunteering count towards school or work community service hours?"
            >
              <>
                <p>
                  Yes! Many of our volunteers are collaborating with us, and through an arrangement between Astrobie LLC and their institutions, they have the opportunity to earn credits toward their degrees in technology fields where support is needed. We strongly encourage students to take advantage of this opportunity by filling out the form and checking the box that says, "I am a college student and would like to have this experience count toward my degree." so we can contact their institution. Please note that this option is available only for undergraduate and graduate students.
                </p>
              </>
            </AccordionItem>
            <AccordionItem
              classNames={{
                title: "font-medium text-md md:text-sm",
                content: "text-[#1d1d1d8e] font-normal text-sm md:text-xs",
              }}
              key="5"
              aria-label="Accordion 5"
              title="Can I organize a fundraiser for this project?"
            >
              <>
                <p>
                  Yes! While individuals may not have the opportunity to collaborate with us directly, organizing a fundraiser for our cause is a great way to contribute. Please note that all fundraisers require explicit permission from Astrobie LLC, and an authorized member of Astrobie LLC must be present at all times. Any fundraising activities conducted without our authorization will be subject to legal action to protect our organization from unauthorized representations or financial exploitation.
                </p>
              </>
            </AccordionItem>
          </Accordion>
          <div className="mt-4 text-right">
            <Link
              to="/faq"
              className="text-[#1D65C1] text-xs"
            >
              More frequently asked questions
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Faq;
