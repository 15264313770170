import React, { useContext, useState, useEffect } from "react";
import { Input, Button, Select, SelectItem } from "@nextui-org/react";
import { StepperContext } from "../Context/StepperContext";
import axios from "axios";

export default function PersonalDetails({ handleClick, currentStep, steps }) {
  const { formData, updateFormData } = useContext(StepperContext);
  const [errors, setErrors] = useState({});
  const [value, setValue] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorLoad, setErrorLoad] = useState(null);


  useEffect(() => {
    // get Country list
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        setCountries(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setErrorLoad(err);
        setLoading(false);
      });
  }, []);

  const sortedCountries = [...countries].sort((a, b) =>
    a.name.common.localeCompare(b.name.common)
  );

  const handleSelectionCountryChange = (e) => {
    setCountry(e.target.value);
    updateFormData({ ...formData, [e.target.name]: e.target.value });
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    updateFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
   
    if (!formData.Name)
      newErrors.Name = "Name is required";
    if (!formData.Country)
      newErrors.Country = "Country is required";
    if (!formData.City)
      newErrors.City = "City is required";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0; // Retorna true si no hay errores
  };

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleClick("next");
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <p className="my-5">Personal Details</p>
      <div className="flex flex-col gap-4">
        <Input
          type="text"
          label="Name"
          onChange={handleChange}
          id="Name"
          name="Name"
          value={formData.Name}
          isRequired
          isInvalid={errors.Name?true:false}
          errorMessage={errors.Name}
        />
        <Select
          label="Country"
          onChange={handleSelectionCountryChange}
          id="Country"
          name="Country"
          placeholder="Select a country"
          selectedKeys={[country]}
          value={formData.Country}
          isRequired
          isInvalid={errors.Country?true:false}
          errorMessage={errors.Country}>
            {sortedCountries.map((country) => (
              <SelectItem key={country.name.common} value={country.name.common}>
                {country.name.common}
              </SelectItem>
            ))}
        </Select>
        <Input
          type="text"
          label="City"
          onChange={handleChange}
          id="City"
          name="City"
          value={formData.City}
          isRequired
          isInvalid={errors.City?true:false}
          errorMessage={errors.City}
        />
      </div>
      <div className="container flex justify-between mt-12 mb-4">
        <Button
          onClick={() => handleClick()}
          className={`bg-[#FDFFFC] text-[#1D1D1D] px-4 py-2 rounded-full 
font-semibold cursor-pointer border-1 border-[#1D1D1D] hover:text-[#FDFFFC] hover:bg-[#1D1D1D] transition duration-200 ease-in-out ${
            currentStep === 1
              ? "opacity-50 cursor-not-allowed"
              : "cursor-pointer"
          }`}
        >
          Back
        </Button>

        <Button
          type="submit"
          className="bg-[#1D65C1] px-4 py-2 rounded-full 
font-semibold  border-1 border-[#1D65C1] text-[#FDFFFC] cursor-pointer"
        >
          {currentStep === steps.length ? "Confirm" : "Next"}
        </Button>
      </div>
    </form>
  );
}
