import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@nextui-org/modal";
import { Button } from "@nextui-org/react";

{
  /* into the body and button, you need to send a function, this is because with that you can perform the button
 that you want to show the modal and show the information that you wish to like you want */
  /* If you want custom the modal you need send a dictionary with the structure  {all: "", header: "", body:"", footer: "" } */
}

export default function BasicModal({
  header = ()=>{},
  body = ()=>{},
  footer = ()=>{},
  button,
  size = "md",
  classNames,
}) {
  const modalVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.9 },
  };

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return (
    <div>
      <div>
        {button(onOpen) ? (
          button(onOpen)
        ) : (
          <Button onClick={onOpen}>open</Button>
        )}
      </div>
      <Modal
        backdrop="opaque"
        placement="center"
        size={size}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        className={` bg-[#FDFFFC] ${classNames.all} `}
        motionProps={{
          variants: modalVariants,
          initial: "hidden",
          animate: "visible",
          exit: "exit",
          transition: { duration: 0.3 },
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader
                className={`flex flex-col gap-1 -z-20 ${classNames.header}`}
              >
                {header()}
              </ModalHeader>
              <ModalBody className={`${classNames.body}`}>{body()}</ModalBody>
              <ModalFooter className={`${classNames.footer}`}>
                <div class="flex justify-between">
                  {footer()}
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}