export const AssistanceData = [
    {
        id: 1,
        title: 'For shops and sells',
        content: 'Ecommerce',
        color: '[#EE964B]',
        description:"Refers to buying and selling products and services online, akin to a busy shopping mall, but in digital form.",
        imageForModal: 'https://images.pexels.com/photos/5632371/pexels-photo-5632371.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },{
        id: 2,
        title: 'For your business projects',
        content: 'Business',
        color: '[#84828F]',
        description:"Its main aim is to manage the production and provision of goods or services for economic purposes.",
        imageForModal: 'https://images.pexels.com/photos/581344/pexels-photo-581344.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },{
        id: 3,
        title: 'For people who love sharing',
        content: 'Blog',
        color: '[#CD8987]',
        description:"A regular record of your thoughts, opinions, or experiences that you put on the internet for other people to read",
        imageForModal: 'https://images.pexels.com/photos/8357680/pexels-photo-8357680.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },{
        id: 4,
        title: 'Promote yourself',
        content: 'Personal Website',
        color: '[#94BB9B]',
        description:"Your own space on the internet, akin to a personalized online brochure. Unlike social media profiles, it offers greater control over both content and design.",
        imageForModal: 'https://images.pexels.com/photos/1239291/pexels-photo-1239291.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },{
        id: 5,
        title: 'Showcase your work',
        content: 'Portfolio',
        description:"A distinctive platform that lets you display your work and share information about yourself—essentially serving as an online business card.",
        imageForModal: 'https://images.pexels.com/photos/590016/pexels-photo-590016.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    }
];