import React from 'react'

function AAMission() {
    return (
        <section className='bg-[#5ab48923] text-[#1D1D1D] px-6 flex items-center justify-center m-auto'>
            <div className='mt-20 mb-20 md:mt-24 md:mb-24 max-w-[1044px] mx-auto'>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
                    <div>
                        <p className="text-4xl lg:text-6xl font-semibold"><span className='text-[#5ab489]'>Free </span>Education For Everyone</p>
                        <p className='mt-4'>Because we believe education should be a right, not a privilege.</p>
                    </div>
                    <div>

                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default AAMission