import { Button, Card, Input } from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import { Cancel, Lupa } from "./resources/Icons";
import { Sections } from "./data";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import "material-icons/iconfont/material-icons.css";

/* 
    HOW IS IT WORK? 
 The component has an Array (it´s saved in ./data), with that data, the input call a function to filter in the keywords of the Array finding all the sections that have matches, 
 we need to change and add relational keywords with all the sections, finally, when the user select one we use the URL saved to redirect the user to the correct section   
*/

function SearchBar({ IsInFloatNav = false }) {
  const [searchQuery, setSearchQuery] = useState("");
  const menuRef = useRef(null);

  const [isMobile, setIsMobile] = useState(false);
  const [open, setOpen] = useState(false);

  const animationProps = IsInFloatNav
    ? {
        initial: { opacity: 0, x: 1 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 1 },
      }
    : {
        initial: { opacity: 0, y: -100 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -20 },
      };

  //Verify the device type
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobileDevice =
      /android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent);
    const isTablet = /iPad|Android(?!.*Mobile)/i.test(userAgent);
    const isSmallScreen = window.innerWidth <= 768;

    if (isTablet || (isSmallScreen && !/windows/i.test(userAgent))) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const handleScroll = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  //Call the action to close the searcher and clear the input
  const handleClick = (state) => {
    if (state) {
      setSearchQuery("");
      setOpen(false);
    } else setOpen(true);
  };

  //Function to filter data using the input values
  const filterData = (query) => {
    if (!query) return [];

    const result = Sections.filter((item) =>
      item.keywords.some(
        (keyword) =>
          keyword.toLowerCase().includes(query.toLowerCase()) ||
          query.toLowerCase().includes(keyword.toLowerCase())
      )
    );

    return result;
  };

  const filteredData = filterData(searchQuery);

  return (
    <div className="">
      {/* Button to show the searcher */}
      <Button
        isIconOnly
        variant="bordered"
        className="border-none"
        onClick={() => {
          handleClick(open);
        }}
      >
        <Lupa />
      </Button>

      {/*Searcher */}
      <AnimatePresence>
        {open && (
          <motion.div
            {...animationProps}
            transition={{ duration: 0.42 }}
            className={`fixed w-full start-0 top-0 h-[3000vh] z-50 lg:-z-10 ${IsInFloatNav?"":"bg-[#f4f6f37a] "}`}
          >
            <Card
              ref={menuRef}
              className={`w-full max-lg:h-full start-0 shadow-none rounded-none rounded-b-lg bg-[#1d1d1d] text-[#fdfffc] ${
                IsInFloatNav ? "rounded-t-3xl mt-6 rounded-b-3xl" : ""
              }`}
              onPointerLeave={() => setOpen(isMobile ? true : false)}
            >
              <Button
                isIconOnly
                className="bg-transparent rounded-full m-3 text-[#fdfffc] self-end lg:hidden"
              >
                <span
                  className="material-icons"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  close
                </span>
              </Button>
              <div className="grid lg:mt-20 w-full place-items-center lg:space-y-5 ">
                <Input
                  startContent={<Lupa />}
                  variant="underlined"
                  size="lg"
                  className="max-lg:px-5 lg:w-[50%] text-3xl"
                  placeholder="Search in Astrobie"
                  value={searchQuery}
                  onChange={handleChange}
                  color={"#FDFFFC"}
                />
              </div>
              {/*  this div shows the result.
                 if the filter does not find matches then show "no results" and 
                 if the input is clear then show a predetermine list */}
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.42 }}
                className="grid m-5 w-[50%] lg:place-items-center lg:ms-16"
              >
                {filteredData.length > 0 ? (
                  <>
                    <p className="text-lg font-light">Suggest Links</p>
                    <ul className="font-medium">
                      {filteredData.map((item) => (
                        <li
                          className="flex hover:text-[#403F4A] hover:translate-x-1 text-sm my-2"
                          onClick={() => handleClick(open)}
                        >
                          <Link to={item.url}>{item.section}</Link>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : searchQuery.length > 0 ? (
                  <p>No results</p>
                ) : (
                  <>
                    <p className="text-lg font-light">Quick Links</p>
                    <ul className="font-medium">
                      <li
                        className="flex hover:text-[#403F4A] hover:translate-x-1 text-sm my-2"
                        onClick={() => handleClick(open)}
                      >
                        <Link to="/academy">Astrobie Academy</Link>
                      </li>
                      <li
                        className="flex hover:text-[#403F4A] hover:translate-x-1 text-sm my-2"
                        onClick={() => handleClick(open)}
                      >
                        <Link to="faq">FAQ</Link>
                      </li>
                      <li
                        className="flex hover:text-[#403F4A] hover:translate-x-1 text-sm my-2"
                        onClick={() => handleClick(open)}
                      >
                        <Link to="about">About Us</Link>
                      </li>
                    </ul>
                  </>
                )}
              </motion.div>
            </Card>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default SearchBar;
