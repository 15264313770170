export const AcademyData = [
    {
        id: 1,
        title: 'Education Everywhere',
        content: 'Opportunities For Everyone',
        image: "/images/1_Opportunities_For_Everyone.svg",
        color: '[#6650d5]'
    },{
        id: 2,
        title: 'Changing Lives',
        content: 'Unlocking Potential',
        image: "/images/2_Unlocking_Potential.svg",
        color: '[#5ab489]'
    },{
        id: 3,
        title: 'Commitment With You',
        content: 'The Goal is Your Success',
        image: "/images/3_The_Goal_is_Your_Success.svg",
        color: '[#ffa664]'
    },{
        id: 4,
        title: 'Transforming Communities',
        content: 'Together for better',
        image: "/images/4_Together_for_Better.svg",
        color: '[#ff6464]'
    }
]

export const ModalsData=[
    {
        id:1,
        header:"Education Everywhere: Opportunities for everyone",
        body:"At Astrobie Academy, we believe in making education accessible to all. Our mission is to provide opportunities for everyone to learn and grow, regardless of their background or circumstances. By offering a wide range of educational resources, we aim to break down barriers and ensure that knowledge is within reach for everyone, everywhere.",
    },
    {
        id:2,
        header:"Changing Lives: Unlocking potential",
        body:"We are dedicated to changing lives by unlocking the potential within each individual. At Astrobie Academy, we empower learners to achieve their dreams and reach their full potential. Through our innovative and inclusive educational programs, we strive to inspire and motivate individuals to pursue their passions and make a positive impact on the world.",
    },
    {
        id:3,
        header:"Commitment with You: The goal is your success",
        body:"Our commitment is to you and your success. At Astrobie Academy, we focus on providing the support and resources you need to achieve your personal and professional goals. We are dedicated to your growth and development, ensuring that our mission aligns with your aspirations and helps you succeed in every endeavor.",
    },
    {
        id:4,
        header:"Transforming Communities: Together for better",
        body:"Together, we are transforming communities for the better. At Astrobie Academy, we believe in the power of collective effort and collaboration. By working hand in hand with our community, we aim to create a more educated and empowered world. Our mission is to foster a sense of unity and purpose, driving positive change and building a brighter future for all.",
    }
]

export const TestimonialsData=[
    {
        id: 1,
        name: 'Johan Olmos',
        title: "Astrobie LLC's Founder and CEO",
        text: " I understood that Astrobie Academy was something special that could change someone's life. I got inspired picturing myself and my team being the helping hand for talented individuals. It's all about helping others.",
        image: '/images/outsourcing_web_assistance.png',
        country:'United States',
        university:'Lewis University',
        color: 'bg-[#6650d5]',
    },
    {
        id: 2,
        name: 'Isaac Orozco',
        title: 'Co-Founder and CTO at Astrobie',
        text: "As co-founder and CTO, Isaac Orozco has played a key role in the technological evolution of Astrobie Academy. His focus on innovation has enabled the academy to leverage technology to deliver high-level educational experiences.",
        image: '/images/outsourcing_web_assistance.png',
        country:'Mexico',
        university:'ITSUR',
        color: 'bg-[#5ab489]',
    },
    {
        id: 3,
        name: 'Nancy Cervantes',
        title: 'Marketing Department Manager',
        text: "I have had the opportunity to meet people with an exceptional vision for improving the world through their knowledge, which has been inspiring. Additionally, I have been able to channel my passion into the work I do and share my experience with other collaborators, which has been extremely gratifying.",
        image: '/images/outsourcing_web_assistance.png',
        country:'Mexico',
        university:'Universidad Tres Culturas',
        color: 'bg-[#ff6464]',
    },
    {
        id: 4,
        name: 'Jose Perez',
        title: "Front-end Developer Intern",
        text: "Being part of Astrobie Academy means having the opportunity to positively impact the lives of many people through education, something I consider a great honor and responsibility. If I could give one message to the world, it would be that education is the key to opening doors and creating a better future.",
        image: '/images/outsourcing_web_assistance.png',
        country:'Mexico',
        university:'ITSUR',
        color: 'bg-[#ffa664]',
    },
    {
        id: 5,
        name: 'Kramer Mwangala',
        title: 'Astrobie Academy Ambassador in Africa',
        text: "Being part of Astrobie Academy is an incredibly enriching experience. Knowing that my work will help improve the lives of many people around the world fills me with happiness and pride. From day one, working with this team has been a very rewarding experience.",
        image: '/images/outsourcing_web_assistance.png',
        country:'Kenya',
        university:'KCA University',
        color: 'bg-[#6650d5]',
    },
    {
        id: 6,
        name: 'Josue Morales',
        title: 'Software Engineer Intern',
        text: "Becoming a member of Astrobie Academy is more than just a role; it's an opportunity to support those who are in the same place I once was. I believe everyone has something to teach us, and we should share our knowledge to become better individuals and contribute to building a world with greater unity and understanding.",
        image: '/images/outsourcing_web_assistance.png',
        country:'Mexico',
        university:'ITSUR',
        color: 'bg-[#5ab489]',
    },
    {
        id: 7,
        name: 'Saul Zavala',
        title: 'Sofware Developer Intern',
        text: "Being part of Astrobie Academy is an incredibly enriching experience. Knowing that my work will help improve the lives of many people around the world fills me with happiness and pride. From day one, working with this team has been a very rewarding experience. I felt very welcomed, and the ease with which both doubts and ideas can be expressed is something I truly value.",
        image: '/images/outsourcing_web_assistance.png',
        country:'Mexico',
        university:'ITSUR',
        color: 'bg-[#ff6464]',
    },
]

export const Sections=[
    {
        section:"Outsourcing",
        keywords:["Outsourcing","Services","offer","work"],
        url:"outsourcing"
    },
    {
        section:"Products",
        keywords:["Products","Services"],
        url:"products"
    },
    {
        section:"Astrobie Academy",
        keywords:["Academy","Astrobie","Education","volunteer","university","lesson","course"],
        url:"/academy"
    },
    {
        section:"News",
        keywords:["News","new","information"],
        url:"news"
    },
    {
        section:"About Us",
        keywords:["About","Us","History"],
        url:"about"
    },
    {
        section:"FAQ",
        keywords:["Questions","FAQ", "Frenquently", "Contact","?"],
        url:"faq"
    },
    {
        section:"privacy policy",
        keywords:["privacy","data","policy","personal","information","Access"],
        url:"/privacy-policy"
    },
    {
        section:"Support",
        keywords:["Support","help","problem","Contact"],
        url:"/Support"
    },
]