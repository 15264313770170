import React, { useEffect, useState } from "react";
import { Button } from "@nextui-org/react";
import {Link} from "react-router-dom";
import Spline from '@splinetool/react-spline';
import { TabTitle } from "../dynamicTitles";

export default function HeroSection({
                                    title = "Product name",
                                    subtitle = "Product name",
                                    image = "/images/interik_product_testing.png",
                                }) {

    TabTitle('Astrobie Card');

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 912);


  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 912);
    };
    window.addEventListener("resize", handleResize);


    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section
      name="HeroSection"
      className="bg-[#000000] m-auto text-[#FDFFFC] py-7 md:py-24"
    >
      <div className="max-w-[1244px] mx-auto ">
        <div className="grid grid-cols-1 mx-9 md:grid-cols-2 gap-6 md:gap-2 items-center justify-center">
          <div className="">
            <p className="text-xl uppercase">innovative</p>
            <div className="text-6xl md:text-6xl font-semibold">
              <span className="text-[#FDFFFC]">
                {title}
              </span>
            </div>
            <p className="text-xl ">{subtitle}</p>
            <p className="text-xs">Subject to availability*</p>
          </div>
          <div className="py-2 h-96" style={{ zoom: isSmallScreen ? 0.62 : 1 }}>
            {/*<img src="/images/astrobie-card.svg" alt="" className="md:float-end"/>*/}
            <Spline
              className=""
              scene="https://prod.spline.design/0zY30DGxTpN4-hFC/scene.splinecode"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
