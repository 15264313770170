import React from 'react'

function Perks() {
  return (
    <section className="bg-[#FDFFFC] px-6 m-auto text-[#1D1D1D] w-[100%] flex items-center justify-center">
        <div className="max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24">
          <div className="text-4xl font-semibold mb-16">
            <span className="text-[#1D1D1D]">Perks for Members</span>
          </div>

          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <p className="text-xl font-medium mb-6">Perks for Volunteers:</p>
                <ul className="grid grid-cols-1 items-center justify-start max-w-full xl:max-w-[90%] text-xs gap-3 mb-10 md:mb-0">
                  <li className="flex items-center justify-center gap-3 px-3 py-2 bg-[#f3f4f5] rounded-xl ">
                    <img src="/images/schollar.png" className="h-14 w-14" alt="Perks"/>
                    <p>Enjoy exclusive benefits sponsored by our university partners, including internships, 
                      scholarships, and professional development opportunities for volunteers.</p>
                  </li>
                  <li className="flex items-center justify-center gap-3 px-3 py-2 bg-[#f3f4f5] rounded-xl ">
                    <img src="/images/network.png" className="h-14 w-14" alt="Perks"/>
                    <p>Connect with educators, industry professionals, and like-minded volunteers who share 
                      a passion for learning and teaching.</p>
                  </li>
                  <li className="flex items-center justify-center gap-3 px-3 py-2 bg-[#f3f4f5] rounded-xl ">
                    <img src="/images/money.png" className="h-14 w-14" alt="Perks"/>
                    <p>Demonstrating commitment and skill through volunteering can lead to potential paid 
                      opportunities within the organization.</p>
                  </li>
                  <li className="flex items-center justify-center gap-3 px-3 py-2 bg-[#f3f4f5] rounded-xl ">
                    <img src="/images/skills.png" className="h-14 w-14" alt="Perks"/>
                    <p>Develop and refine skills related to teaching, curriculum design, and online content 
                      creation. Additionally, join the IT team to gain technical skills in areas such as 
                      web development, software management, and troubleshooting.</p>
                  </li>
                  <li className="flex items-center justify-center gap-3 px-3 py-2 bg-[#f3f4f5] rounded-xl ">
                    <img src="/images/love.png" className="h-14 w-14" alt="Perks"/>
                    <p>Make a lasting impact by helping others and contributing to a legacy of education 
                      that changes lives. Experience the fulfillment of knowing your efforts are making a 
                      difference in the world</p>
                  </li>
                </ul>
              </div>

              <div>
                <p className="text-xl font-medium mb-6">Perks for Universities and Organizations:</p>
                  <ul className="grid grid-cols-1 items-center justify-start max-w-full xl:max-w-[90%] text-xs gap-3">
                    <li className="flex items-center justify-center gap-3 px-3 py-2 bg-[#f3f4f5] rounded-xl ">
                      <img src="/images/blog.png" className="h-14 w-14" alt="Perks"/>
                      <p>Gain worldwide exposure! Our partners can engage closely with their Astrobie Academy 
                        students through a dedicated blog section, allowing for meaningful interactions and 
                        connection.</p>
                    </li>
                    <li className="flex items-center justify-center gap-3 px-3 py-2 bg-[#f3f4f5] rounded-xl ">
                      <img src="/images/data.png" className="h-14 w-14" alt="Perks"/>
                      <p>Access comprehensive analytics! Institutions can obtain data on the courses and 
                        resources they publish on our platform. Discover which courses are gaining popularity 
                        and understand the reasons behind it. All your course data is readily available.</p>
                    </li>
                    <li className="flex items-center justify-center gap-3 px-3 py-2 bg-[#f3f4f5] rounded-xl ">
                      <img src="/images/person.png" className="h-14 w-14" alt="Perks"/>
                      <p>Enable your students to engage and gain exclusive access to our special content 
                        designed just for them.</p>
                    </li>
                    <li className="flex items-center justify-center gap-3 px-3 py-2 bg-[#f3f4f5] rounded-xl ">
                      <img src="/images/world.png" className="h-14 w-14" alt="Perks"/>
                      <p>Partnership events offer students the opportunity to collaborate and travel with 
                        Astrobie Academy. Join us in making a difference and gain recognition for your 
                        involvement.</p>
                    </li>
                  </ul>
              </div>
            </div>
          </div>

        </div>
    </section>
    

  )
}

export default Perks