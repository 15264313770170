import React, {useEffect, useState} from 'react'
import UniversityForm from './UniversityForm'

function University() {

    return(
        <UniversityForm/>
    )
}

export default University