import React from 'react';

function AAHero() {
    return (
        <section className='bg-[#FDFFFC] text-[#1D1D1D] w-[100%] flex-col items-center justify-center'>
            <div className='mt-20 mb-24 md:mt-24 md:mb-24 px-6 mx-auto flex-column content-center static gap-8 max-w-8xl'>

                <div className='mb-10 block md:hidden'>
                    <p className='text-4xl lg:text-6xl font-semibold mb-2'>Astrobie Academy</p>
                    <p className='text-medium lg:text-xl mb-4'>Great Futures Arise from Strong Support for 
                        <span className="relative inline-block px-2">
                            <span className="absolute inset-0 bg-[#5ab489] opacity-50 rounded-full animate-pulse"></span>
                            <span className="relative z-10">Education</span>
                        </span>
                    </p>
                </div>

                <div className='grid grid-cols-2 lg:grid-cols-5 grid-rows-12 gap-2 h-[100vh]'>
                    <div className='hidden col-span-3 col-start-2 row-span-2 md:row-span-1 md:flex items-center justify-center'>
                        <p className='text-4xl lg:text-7xl font-semibold'>Astrobie Academy</p>
                    </div>

                    <div className='hidden col-span-3 col-start-2 row-span-1 md:row-span-1 md:flex items-center justify-center'>
                        <p className='text-medium lg:text-xl'>Great Futures Arise from Strong Support for
                            <span className="relative inline-block ml-1 px-2">
                                <span className="absolute inset-0 bg-[#5ab489] opacity-50 rounded-full animate-pulse"></span>
                                <span className="relative z-10">Education</span>
                            </span>
                        </p>
                    </div>

                    {/* Blocks below */}

                    <div className='relative col-span-1 col-start-1 row-span-4 md:row-span-5 rounded-xl 
                    bg-[#1D1D1D] h-full row-start-1 md:row-start-4 row-end-4 md:row-end-10 overflow-hidden group shadow-xl'>
                        <img className='h-full w-full rounded-xl object-cover group-hover:scale-125 duration-1000' alt='Empowering Dreams'
                            src='https://images.pexels.com/photos/1667850/pexels-photo-1667850.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'/>
                        
                            <h2 className='absolute bottom-4 left-4 text-[#ffffff] text-lg xl:text-3xl 2xl:text-4xl font-semibold group-hover:hidden'>Empowering Dreams, One Lesson at a Time</h2>
                        
                        <div className='absolute inset-0 flex-col px-5 py-5 items-center justify-center bg-[#ffa664] bg-opacity-60 transform translate-y-full opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-transform transition-opacity duration-500'>
                            <p className='text-[#fdfffc] hidden md:block xl:text-3xl 2xl:text-4xl font-semibold'>Empowering Dreams, One Lesson at a Time</p>
                                <p className='text-sm text-[#fdfffc] mt-3'>Astrobie Academy is designed for all learners, 
                                    recognizing the diverse journeys and experiences of each individual. 
                            </p>
                        </div>
                    </div>
                    
                    <div className='col-span-1 col-start-1 row-span-2 rounded-xl 
                    bg-[#6650d5] bg-opacity-70 px-4 py-4 h-full row-start-4 row-end-6 md:row-start-10 md:row-end-12 shadow-xl'>
                        <p className='text-[#fdfffc] text-sm xl:text-medium 2xl:text-xl font-semibold'>"People with passion and talent must change the world for the better"</p>
                    </div>
                    
                    {/* Column 2 */}
                    <div className='relative col-span-2 md:col-span-1 col-start-1 md:col-start-2 row-span-4 md:row-span-5 row-start-6 
                        md:row-start-6 row-end-10 md:row-end-12 h-auto md:h-full bg-[#1d1d1d] rounded-xl overflow-hidden group shadow-xl'>
                        <img src='https://images.pexels.com/photos/5905899/pexels-photo-5905899.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' 
                            alt='Taught by Leaders' className='h-full w-full rounded-xl object-cover group-hover:scale-125 duration-1000'/>
                        
                        <h2 className='absolute bottom-4 left-4 text-[#ffffff] text-4xl font-semibold group-hover:hidden'>Taught by Leaders</h2>
                        
                        <div className='absolute inset-0 flex-col px-5 py-5 items-center justify-center bg-[#5ab489] bg-opacity-60 transform translate-y-full opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-transform transition-opacity duration-500'>
                            <p className='text-[#fdfffc] text-4xl font-semibold'>Taught by Leaders</p>
                            <p className='text-xs text-[#fdfffc] mt-3'>By partnering with colleges, universities, and qualified 
                                professionals, we ensure that all our content meets the highest standards of education. This 
                                commitment allows our community to gain valuable knowledge and skills that will have a meaningful 
                                impact on their careers.
                            </p>
                        </div>
                    </div>


                    {/* Colum 3 */}
                    <div className='relative col-span-2 md:col-span-1 col-start-1 md:col-start-3 md:row-span-4 row-span-2
                    row-start-10 md:row-start-8 row-end-12 h-full bg-[#1d1d1d] rounded-xl overflow-hidden group shadow-xl'>
                        <img src='https://images.pexels.com/photos/25682484/pexels-photo-25682484/free-photo-of-close-up-of-people-with-their-hands-painted-with-variety-of-colors.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' 
                        alt='We Can Change The World' className='h-full w-full rounded-xl object-cover group-hover:scale-125 duration-1000'/>
                        <h2 className='absolute bottom-4 left-4 text-[#ffffff] text-3xl md:text-4xl font-semibold group-hover:hidden'>Together, We Can Change the World</h2>
                        
                        <div className='absolute inset-0 flex-col px-5 py-5 items-center justify-center bg-[#6650d5] bg-opacity-60 transform translate-y-full opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-transform transition-opacity duration-500'>
                            <p className='text-[#fdfffc] hidden md:block text-base md:text-2xl font-semibold'>Together, We Can Change the World</p>
                            <p className='text-xs text-[#fdfffc] mt-3'>Every learner is a change-maker. By investing in education, we are sowing the seeds for a more equitable future.
                            </p>
                        </div>
                    </div>

                    {/* Column 4 */}
                    <div className='relative hidden md:block col-span-1 col-start-4 row-span-5 row-start-6 
                    row-end-12 h-full bg-[#1d1d1d] rounded-xl shadow-xl overflow-hidden group'>
                        <img src='https://images.pexels.com/photos/1454360/pexels-photo-1454360.jpeg' 
                        alt='Tailored for Everyone' className='h-full w-full rounded-xl object-cover group-hover:scale-125 duration-1000'/>

                        <h2 className='absolute bottom-4 left-4 text-[#fdfffc] text-4xl font-semibold group-hover:hidden'>Tailored for Everyone</h2>
                        
                        <div className='absolute inset-0 flex-col px-5 py-5 items-center justify-center bg-[#ffa664] bg-opacity-60 transform translate-y-full opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-transform transition-opacity duration-500'>
                            <p className='text-[#fdfffc] text-4xl font-semibold'>Tailored for Everyone</p>
                            <p className='text-sm text-[#fdfffc] mt-3'>
                                Our platform is designed to meet the unique needs of every learner. With courses 
                                available in multiple languages, you can choose the language that resonates with you.
                            </p>
                        </div>
                    </div>
                    

                    
                    {/* Column 5 */}
                    <div className='relative col-span-1 col-start-2 md:col-start-5 row-span-4 md:row-span-5 rounded-xl 
                    bg-[#1D1D1D] h-full row-start-3 md:row-start-4 row-end-6 md:row-end-10 overflow-hidden group shadow-xl'>
                        <img src='https://images.pexels.com/photos/159299/graphic-design-studio-tracfone-programming-html-159299.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
                         className='h-full w-full rounded-xl object-cover group-hover:scale-125 duration-1000' alt='STEM'/>
                         <h2 className='absolute bottom-4 left-4 text-[#fdfffc] text-3xl md:text-4xl font-semibold group-hover:hidden'>Practical STEM Courses</h2>
                        
                        <div className='absolute inset-0 flex-col px-5 py-5 items-center justify-center bg-[#ff6464] bg-opacity-60 transform translate-y-full opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-transform transition-opacity duration-500'>
                            <p className='text-[#fdfffc] hidden md:block text-4xl font-semibold'>Practical STEM Courses</p>
                            <p className='text-sm text-[#fdfffc] mt-3'>Forget the fluff—our curriculum focuses on practical 
                                STEM courses designed to equip you with skills that lead to real-life job opportunities.
                            </p>
                        </div>
                    </div>

                    <div className='col-span-1 col-start-2 md:col-start-5 row-span-2 rounded-xl 
                    bg-[#5ab489] bg-opacity-70 px-4 py-4 h-full row-start-1 md:row-start-10 shadow-xl'>
                        <p className='text-[#fdfffc] text-sm lg:text-lg 2xl:text-xl font-semibold'>"Unlock Your Potential: Education is the Key to a Brighter Future"</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AAHero