import React from 'react'

function AADescription() {
  return (
        <section className="px-6 m-auto text-[#1D1D1D] w-[100%] flex items-center justify-center">
            <div className="flex w-full flex-col max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24">
                <div className="text-4xl font-semibold mb-8">
                    <span className="text-[#1D1D1D]">
                        Get to Know Our Initiative
                    </span>
                </div>
                
                <div className='grid grid-cols-1 lg:grid-cols-3 gap-6 mt-4'>
                    <div className='px-7 py-7 rounded-xl bg-[#f3f4f5]'>
                        <div className='flex-col flex items-center justify-center mt-4'>
                            <img src='/images/mission.png' alt='Mission' className='size-24'/>
                            <p className='text-2xl my-4'>Mission</p>
                        </div>
                        <p className='text-xs'>At Astrobie LLC, we are dedicated to empowering 
                            talented individuals who lack access to conventional education. Our 
                            mission is to bridge the educational gap, providing opportunities 
                            for growth and success to those who need it most. We believe that 
                            "people with passion must change the world for the better," and we 
                            strive to nurture that passion in every learner we serve. </p>
                    </div>
                    
                    <div className='px-7 py-7 rounded-xl bg-[#f3f4f5]'>
                        <div className='flex-col flex items-center justify-center mt-4'>
                            <img src='/images/we-are.png' alt='Who We Are' className='size-24'/>
                            <p className='text-2xl my-4'>Who We Are</p>
                        </div>
                        <p className='text-xs'>Astrobie Academy, an initiative by Astrobie LLC, is an upcoming online 
                            platform designed to provide free courses and educational resources 
                            to learners worldwide. Our primary focus is to empower individuals 
                            in Africa and Latin America, bringing education to remote areas and 
                            transforming opportunities for talented individuals who lack access 
                            to traditional educational pathways.</p>
                    </div>

                    <div className='px-7 py-7 rounded-xl bg-[#f3f4f5bc]'>
                        <div className='flex-col flex items-center justify-center mt-4'>
                            <img src='/images/study.png' alt='study' className='size-24'/>
                            <p className='text-2xl my-4'>Area of Study</p>
                        </div>
                        <p className='text-xs'>At Astrobie Academy, we will emphasize courses in science, 
                            technology, engineering, and mathematics (STEM). Recognizing the rapid growth of the STEM 
                            field, which is projected to create millions of jobs globally, we are committed to 
                            harnessing these opportunities for everyone. Our goal is to reach and support talented 
                            individuals in these vital areas.</p>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default AADescription