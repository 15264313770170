import React from 'react';
import {Button} from "@nextui-org/react";
import {useState, useEffect} from 'react';

function ScrollToTopButton() {

    const [backToTopButton, setBackToTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                setBackToTopButton(true)
            }
            else {
                setBackToTopButton(false)
            }
        })
    }, [])

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    return (
        <div
            className='flex justify-center items-center'>
            {backToTopButton && (
                <Button className=' shadow-lg z-40  ease-in-out mx-5 my-5 right-0 bottom-0 fixed bg-[#1d1d1d] border-[#fdfffc23] border-small text-[#FDFFFC] rounded-full' onClick={scrollUp}>
                    <p className='text-[#FDFFFC] text-sm '>Back To Top</p>
                </Button>
            )}
        </div>
    )
}

export default ScrollToTopButton