import React from 'react';
import { motion } from 'framer-motion';


function Solution() {


    return (
        <section name='solution' className='bg-gradient-to-t from-[#0a0f14] to-[#060606] px-6 m-auto text-[#FDFFFC] w-[100%] flex items-center justify-center'>
            <div className='lg:max-w-[1044px] xl:max-w-[1244px] mt-20 mb-24 md:mt-24 md:mb-24  items-center justify-center'>
                <div className='text-4xl md:text-6xl mt-20 font-medium flex justify-center items-center'>
                        <p>We Are&nbsp;<span className='text-[#FDFFFC]'>Astrobie</span></p>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 my-40 gap-10'>
                    <motion.div 
                        initial={{opacity: 0, y: 100}}
                        whileInView={{opacity: 1, y: 0}}
                        transition={{duration: 0.7}}
                        viewport={{once: true}}
                        className='flex text-left justify-center flex-col'>
                            <p className="bg-clip-text text-transparent bg-gradient-to-r from-[#808996] to-[#1D1D1D] text-4xl md:text-5xl font-semibold">Professionalism</p>
                            <p className='text-sm mt-2 text-justify'>Our commitment to professionalism is reflected in our meticulous attention to detail, our adherence to industry best practices, and our dedication to continuous learning and improvement. We understand that our clients entrust us with their most critical technological needs, and we honor that trust by upholding the highest standards of professionalism in every project we undertake.</p>
                    </motion.div>
                    <div>

                    </div>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 my-40 gap-10 text-right'>
                    <div>

                    </div>
                    <motion.div 
                        initial={{opacity: 0, y: 100}}
                        whileInView={{opacity: 1, y: 0}}
                        transition={{duration: 0.7}}
                        viewport={{once: true}}
                        className='flex items-end justify-center flex-col'>
                            <p className="pb-2 bg-clip-text text-transparent bg-gradient-to-r from-[#776491] to-[#4b3070] text-4xl md:text-5xl font-semibold">High Quality</p>
                            <p className='text-sm mt-2 text-justify'>We believe that the quality of our products is a direct result of the processes we use and the people who create them. Our team is composed of industry experts who bring a wealth of knowledge and experience to the table.</p>
                    </motion.div>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 my-40 gap-10'>
                    <motion.div 
                        initial={{opacity: 0, y: 100}}
                        whileInView={{opacity: 1, y: 0}}
                        transition={{duration: 0.7}}
                        viewport={{once: true}}
                        className='flex items-start justify-center flex-col'>
                            <p className="bg-clip-text text-transparent bg-gradient-to-r from-[#CD8987] to-[#a05956] text-4xl md:text-5xl font-semibold">Commitment</p>
                            <p className='text-sm mt-2 text-justify'>We are particularly committed to supporting rural communities around the world, many of which have been historically underserved by the technology sector. Our focus is on providing game-changing services that can help these communities overcome geographical barriers, enhance their economies, and improve their quality of life.</p>
                    </motion.div>
                    <div>

                    </div>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 my-40 gap-10 text-right'>
                    <div>

                    </div>
                    <motion.div 
                        initial={{opacity: 0, y: 100}}
                        whileInView={{opacity: 1, y: 0}}
                        transition={{duration: 0.7}}
                        viewport={{once: true}}
                        className='flex items-end justify-center flex-col'>
                            <p className="bg-clip-text text-transparent bg-gradient-to-r from-[#7EB265] to-[#497c31] text-4xl md:text-5xl font-semibold">The Green Way</p>
                            <p className='text-sm mt-2 text-justify'>We understand that as a technology company, we have a responsibility to minimize our carbon footprint and strive towards carbon neutrality. Furthermore, we are continually exploring innovative ways to make our products and operations more environmentally friendly.</p>
                    </motion.div>
                </div>


                <div className='grid grid-cols-1 md:grid-cols-2 my-40 gap-10'>
                    <motion.div 
                        initial={{opacity: 0, y: 100}}
                        whileInView={{opacity: 1, y: 0}}
                        transition={{duration: 0.7}}
                        viewport={{once: true}}
                        className='flex items-start justify-center flex-col'>
                            <p className="bg-clip-text text-transparent bg-gradient-to-r from-[#4c99ff] to-[#14478a] text-4xl md:text-5xl font-semibold">Impact on Small Businesses</p>
                            <p className='text-sm mt-2 text-justify'>We understand the unique challenges that small businesses face in the technology landscape, and we believe that they are the backbone of innovation and economic growth. From major discounts to services specialized in small business development, we are proud of being part of their success.</p>
                    </motion.div>
                    <div>

                    </div>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 mt-40 mb-20 gap-10 text-right'>
                    <div>

                    </div>
                    <motion.div 
                        initial={{opacity: 0, y: 100}}
                        whileInView={{opacity: 1, y: 0}}
                        transition={{duration: 0.7}}
                        viewport={{once: true}}
                        className='flex items-end justify-center flex-col'>
                            <p className="bg-clip-text text-transparent bg-gradient-to-r from-[#EE964B] to-[#da731a] text-4xl md:text-5xl font-semibold">Focused on You</p>
                            <p className='text-sm mt-2 text-justify'>At Astrobie, we place our customers at the heart of everything we do. We understand that every customer is unique, with distinct needs and challenges. That's why we adopt a customer-centric approach, tailoring our technology solutions to meet the specific requirements of each client.</p>
                    </motion.div>
                </div>

            </div>
            
        </section>
    )
}

export default Solution