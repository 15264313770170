import React from 'react'
import {Card, CardHeader, CardBody, CardFooter, Button, Snippet} from "@nextui-org/react";
import { Link } from 'react-router-dom';

function ContactUs() {
    return (
        <section className='bg-[#FDFFFC] px-6 m-auto text-[#1D1D1D] w-[100%] flex items-center justify-center'>
            <div className='max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24  items-center justify-center'>

                <div className="text-4xl font-semibold">
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#1D1D1D] to-[#1D1D1D]">
                        Contact Astrobie
                    </span>
                </div>
                <p className="text-xl mb-8">Access all of our resources</p>

                <div className='grid grid-col-1 gap-10 md:grid-cols-2 lg:grid-cols-4'>
                    <div>
                        <Card className="py-4">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-start h-24">
                                <p className="font-medium text-medium">Contact for</p>
                                <p className='font-semibold text-2xl text-[#1D1D1D]'>Volunteering</p>
                                <p className='text-xs font-semibold mt-1 text-[#1d1d1d85]'>Astrobie Academy is the result of kindness</p>
                            </CardHeader>
                            <CardBody className="overflow-visible py-2">
                                {/* Image Here */}
                            </CardBody>
                            <CardFooter className='flex items-center justify-center'>
                                <Link to="mailto:volunteering@astrobie.com">
                                    <Button className='bg-[#1D65C1] text-[#FDFFFC]' radius='full'>&#9993; Email Us</Button>
                                </Link>
                            </CardFooter>
                        </Card>
                        <div className='flex items-center justify-center mt-4'>
                            <Snippet className={'bg-[#1D64C1] text-[#FDFFFC] rounded-full'} symbol="">volunteering@astrobie.com</Snippet>
                        </div>
                    </div>

                    <div>
                        <Card className="py-4">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-start h-24">
                                <p className="font-medium text-medium">Contact for</p>
                                <p className='font-semibold text-2xl text-[#1D1D1D]'>Partnerships</p>
                                <p className='text-xs font-semibold mt-1 text-[#1d1d1d85]'>Let's be stronger together</p>
                            </CardHeader>
                            <CardBody className="overflow-visible py-2">
                                {/* Image Here */}
                            </CardBody>
                            <CardFooter className='flex items-center justify-center'>
                                <Link to="mailto:partners@astrobie.com">
                                    <Button className='bg-[#1D65C1] text-[#FDFFFC]' radius='full'>&#9993; Email Us</Button>
                                </Link>
                            </CardFooter>
                        </Card>
                        <div className='flex items-center justify-center mt-4'>
                            


                            <Snippet className={'bg-[#1D64C1] text-[#FDFFFC] rounded-full'} symbol="">partners@astrobie.com</Snippet>
                        </div>
                    </div>

                    <div>
                        <Link to="mailto:contact@astrobie.com">
                            <Card className="py-4">
                                <CardHeader className="pb-0 pt-2 px-4 flex-col items-start h-24">
                                    <p className="font-medium text-medium">Contact for</p>
                                    <p className='font-semibold text-2xl text-[#1D1D1D]'>Custom Message</p>
                                    <p className='text-xs font-semibold mt-1 text-[#1d1d1d85]'>Let us hear from you</p>
                                    
                                </CardHeader>
                                <CardBody className="overflow-visible py-2">
                                    {/* Image Here */}
                                </CardBody>
                                <CardFooter className='flex items-center justify-center'>
                                    <Link to="mailto:contact@astrobie.com">
                                        <Button className='bg-[#1D65C1] text-[#FDFFFC]' radius='full'>&#9993; Email Us</Button>
                                    </Link>
                                </CardFooter>
                            </Card>
                        </Link>
                        <div className='flex items-center justify-center mt-4'>
                            <Snippet className={'bg-[#1D64C1] text-[#FDFFFC] rounded-full'} symbol="">contact@astrobie.com</Snippet>
                        </div>
                    </div>

                        <Card className="py-4">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-start h-24">
                                <p className="font-medium text-medium">Contact for</p>
                                <p className='font-semibold text-2xl text-[#94BB9B]'>Social Media</p>
                                <p className='text-xs mt-2'>Astrobie everywhere</p>
                            </CardHeader>
                            <CardBody className="overflow-visible py-2 flex items-center justify-center">
                                {/* Image Here */}
                                <Link to="mailto:contact@astrobie.com">
                                    <Button radius='full'>&#9993; Email Us</Button>
                                </Link>
                            </CardBody>   
                            <CardFooter className='flex items justify-center'>
                                <Snippet className={'bg-[#1D64C1] text-[#FDFFFC] rounded-full'} symbol="">contact@astrobie.com</Snippet>
                            </CardFooter>
                        </Card>
                </div>
            </div>
        </section>
    )
}

export default ContactUs