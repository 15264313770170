import {
  Accordion,
  AccordionItem,
  Button,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
  useDisclosure,
} from "@nextui-org/react";
import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase/config";
import Toast from "../Alert";

export default function AAFAQ() {
  const FAQs = [
    {
      Question: "How do I apply to become a volunteer?",
      Content:
      (
        <>
          Whether you wish to volunteer as an individual or represent an institution interested in partnering with the Academy initiative, you can complete the volunteering form found in the “Resources” section at{" "}
          <Link to="https://astrobie.com/academy" className="text-[#1d65c1]">astrobie.com/academy</Link>
          . There, you will find an online form for both individual volunteers and institutions; please select the option that best suits your needs.
        </>
      )},
    {
      Question: "Can I volunteer remotely, or are all activities in person?",
      Content:
        "If you reside outside the United States and are interested in becoming a partner or volunteering for this cause, you are welcome to apply for a remote position. Remote roles are typically available for individuals located outside the U.S., and we currently have active members and institutions from various countries. We look forward to your involvement!",
    },
    {
      Question: "What does it mean for my institution to join Astrobie Academy?",
      Content:
        "If your organization or institution becomes a member of Astrobie Academy, you'll enjoy a variety of benefits (see the “Perks” section below for details). Additionally, there are numerous ways your organization or institution can contribute, including donating educational resources and courses, making financial contributions, hosting international events, offering student internships, and more.",
    },
    {
      Question: "Can I support the project without volunteering?",
      Content:
        "Yes! We will soon introduce a donations channel where anyone can contribute. Institutions are also invited to join as partners, allowing us to develop more courses and content for our learners in Africa, Asia, and Latin America. Your support will make a significant impact on our mission!",
    },
    {
      Question:
        "Can volunteering count towards school or work community service hours?",
      Content:
        'Yes! Many of our volunteers are collaborating with us, and through an arrangement between Astrobie LLC and their institutions, they have the opportunity to earn credits toward their degrees in technology fields where support is needed. We strongly encourage students to take advantage of this opportunity by filling out the form and checking the box that says, "I am a college student and would like to have this experience count toward my degree". so we can contact their institution. Please note that this option is available only for undergraduate and graduate students.',
    },
    {
      Question: "Can I organize a fundraiser for this initiative?",
      Content:
        "While some individuals may not have the opportunity to collaborate with us serving in a certain way, organizing a fundraiser for our cause is a great way to contribute. Please note that all fundraisers require explicit permission from Astrobie LLC, and an authorized member of Astrobie LLC must be present at all times. Any fundraising activities conducted without our authorization will be subject to legal action to protect our organization from unauthorized representations or financial exploitation.",
    },
  ];

  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (
      /android/i.test(userAgent) ||
      (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
    ) {
      return true;
    }
    return window.innerWidth <= 767;
  };

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [isSelected, setIsSelected] = React.useState(false);

  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Handle the form submit
  const onSubmit = (data) => {
    //console.log(data);
    const questionRef = collection(db, "faq");
    addDoc(questionRef, data)
      .then((doc) => {
        onOpenChange(false);
        reset();
        Toast.fire({
          icon: "success",
          title: "Good question! Someone will review and answer your question",
        });
      })
      .catch((reason) => {
        Toast.fire({
          icon: "error",
          title: "We have had some problems, please try again.",
        });
      });
  };

  return (
    <section
      name="faq"
      className="p-0 m-0 py-36 bg-[#FDFFFC]  flex-col items-center justify-center w-[100%]"
    >
      <div className=" px-6 text-[#1D1D1D] mx-auto flex-column content-center static gap-8 max-w-[1144px]">
        <div className="pl-2 mb-8">
          <p className="text-4xl font-semibold text-left">
            Frequently Asked Questions
          </p>
        </div>

        <div className="items-center">
          <Accordion className="ms-6">
            {FAQs.map((faq, index) => (
              <AccordionItem
                classNames={{
                  title: "font-medium text-md md:text-sm",
                  content: "text-[#1d1d1d8e] font-normal text-sm md:text-xs",
                }}
                key={index}
                aria-label={"Question " + index}
                title={faq.Question}
              >
                {faq.Content}
              </AccordionItem>
            ))}
          </Accordion>
        </div>

        <div className="mt-4 text-right">
            <Link
             to={"/faq"}
              className="text-[#1D65C1] text-xs"
            >
              More frequently asked questions
            </Link>
          </div>

      
      </div>
    </section>
  );
}
