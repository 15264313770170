import React, { useContext, useState } from "react";
import { Input, Button, SelectItem, Select, Checkbox } from "@nextui-org/react";
import { StepperContext } from "../Context/StepperContext";

export default function ContactInfo({ handleClick, currentStep, steps }) {
  const { formData, updateFormData } = useContext(StepperContext);
  const [errors, setErrors] = useState({});
  const [value, setValue] = useState("");
  const [isSelected, setIsSelected] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(formData);
    updateFormData({ ...formData, [name]: value });
  };

  const handleCheck = () => {
    updateFormData({ ...formData, ["IsCollegeStudent"]: !isSelected });
    setIsSelected(!isSelected);

  };

  const handleSelectionChange = (e) => {
    setValue(e.target.value);
    updateFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    //put here the messages for the inputs with key like input id or name
    const validationRules = {
      firstName: "Business name is required",
      lastName: "Business address  is required",
      contactEmail: "Type is required",
      contactEmailconfirm: "is Required",
      contactPhone: "Phone number is required",
      gender: "Select a gender",
    };

    const newErrors = {};
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.contactEmail))
      newErrors.contactEmail = " invalid email format";

    if (!/^[0-9]{10}$/.test(formData.contactPhone))
      newErrors.contactPhone = " invalid number format";

    if (formData.contactEmail !== formData.contactEmailconfirm)
      newErrors.contactEmailconfirm = "email address does not match";

    for (const [field, errorMessage] of Object.entries(validationRules)) {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
      }
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0; // Retorna true si no hay errores
  };

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleClick("next");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="my-6 text-3xl font-semibold">Contact Information</p>
      <div className="grid grid-cols-2 gap-4">
        {/**First name input*/}
        <Input
          className="col-span-2 md:col-auto"
          type="text"
          label="First Name"
          onChange={handleChange}
          id="firstName"
          name="firstName"
          value={formData.firstName}
          isRequired
          isInvalid={errors.firstName ? true : false}
          errorMessage={errors.firstName}
        />
        {/**Last name input*/}
        <Input
          className="col-span-2 md:col-auto"
          type="text"
          label="Last Name"
          onChange={handleChange}
          id="lastName"
          name="lastName"
          value={formData.lastName}
          isRequired
          isInvalid={errors.lastName ? true : false}
          errorMessage={errors.lastName}
        />

        {/**Gender */}
        <Select
          size="lg"
          name="gender"
          id="gender"
          label="Gender"
          labelPlacement="outside-left"
          isRequired
          placeholder="Choose your gender"
          selectedKeys={[value]}
          className="items-center w-full col-span-2 md:col-auto"
          onChange={handleSelectionChange}
        >
          <SelectItem key={"Male"}>Male</SelectItem>
          <SelectItem key={"Female"}>Female</SelectItem>
          <SelectItem key={"Non-binary"}>Non-binary</SelectItem>
          <SelectItem key={"Other"}>Other</SelectItem>
          <SelectItem key={"I prefer not to say it"}>
            I prefer not to say it
          </SelectItem>
        </Select>

        {/**Phone number */}
        <Input
          className="col-span-2 md:col-auto"
          type="number"
          label="Phone Number"
          onChange={handleChange}
          id="contactPhone"
          name="contactPhone"
          value={formData.contactPhone}
          isRequired
          isInvalid={errors.contactPhone ? true : false}
          errorMessage={errors.contactPhone}
        />

        {/**Email address */}
        <Input
          className="col-span-2 md:col-auto"
          type="email"
          label="Email Address"
          onChange={handleChange}
          id="contactEmail"
          name="contactEmail"
          value={formData.contactEmail}
          isRequired
          isInvalid={errors.contactEmail ? true : false}
          errorMessage={errors.contactEmail}
        />
        {/*confirm email */}
        <Input
          className="col-span-2 md:col-auto"
          type="email"
          label="Confirm Email Address"
          onChange={handleChange}
          id="contactEmailconfirm"
          name="contactEmailconfirm"
          value={formData.contactEmailconfirm}
          isRequired
          isInvalid={errors.contactEmailconfirm ? true : false}
          errorMessage={errors.contactEmailconfirm}
        />
        <Checkbox
          className="col-span-2"
          id="IsCollegeStudent"
          name="IsCollegeStudent"
          onValueChange={handleCheck}
          //onChange={handleCheck}
          isSelected={isSelected}
        >
          I am a college student and would like to have this experience count
          toward my degree
        </Checkbox>

        <p className="col-span-2 mt-5 font-light">
          We will never share or sell your information with others.
        </p>
      </div>

      <div className="container flex justify-between my-4">
        <Button
          onClick={() => handleClick()}
          className={`bg-[#FDFFFC] text-[#1D1D1D] px-4 py-2 rounded-full 
font-semibold cursor-pointer border-1 border-[#1D1D1D] hover:text-[#FDFFFC] hover:bg-[#1D1D1D] transition duration-200 ease-in-out ${
            currentStep === 1
              ? "opacity-50 cursor-not-allowed"
              : "cursor-pointer"
          }`}
        >
          Back
        </Button>

        <Button
          type="submit"
          className="bg-[#1D65C1] px-4 py-2 rounded-full 
font-medium  border-1 border-[#1D65C1] text-[#FDFFFC] cursor-pointer"
        >
          {currentStep === steps.length ? "Confirm" : "Next"}
        </Button>
      </div>
    </form>
  );
}
