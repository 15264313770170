import React, {useEffect, useState} from 'react'
import { Accordion, AccordionItem } from "@nextui-org/accordion";
import {Card, Skeleton} from "@nextui-org/react";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../../firebase/config";
import OutsourcingCard from "./OutsourcingCard";
import {twMerge} from "tailwind-merge";
import clsx from "clsx";



function Outsourcing(props) {


    const [outsourcingCards, setOutsourcingCards] = useState([])
    const [loading, setLoading] = useState(true); // INITIAL STATE

    const defaultContent =
        "Outsourcing is when a company hires an external organization to handle specific tasks or services instead of doing them in-house. This allows the company to focus on its core activities while benefiting from the expertise and efficiency of specialized providers. At Astrobie, our outsourcing services help institutions improve businesses, reduce costs, and access advanced skills and technologies, enabling them to concentrate on their primary goals.";


    const classNames = {
        section: twMerge(clsx("bg-[#FDFFFC] text-[#1D1D1D] px-6 flex items-center justify-center m-auto h-auto"), props.classNames?.section)
    }

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const getProducts = async () => {
            try {
                // VERIFY LOCAL DATA
                if (outsourcingCards.length === 0) {
                    // console.log("Fetching from server...");
                    const querySnapshot = await getDocs(collection(db, "outsourcing-cards"), {
                        signal,
                    });
                    const docs = [];
                    querySnapshot.forEach((doc) => {
                        docs.push({ ...doc.data(), id: doc.id });
                    });
                    // console.log("Data fetched from server:", docs);
                    setOutsourcingCards(docs);
                } else {
                    // console.log("Using local data:", products);
                }
                setLoading(false); // END LOAD
            } catch (error) {
                // console.error("ERROR fetching data:", error);
            }
        };

        getProducts();

        return () => {
            // REQUEST CANCELED IF COMPONENT UNMOUNTED
            // console.log("Aborting request...");
            controller.abort();
        };
    }, [outsourcingCards]); // DEPENDENCY TO AVOID UNNECESSARY REQUEST

    if (loading) {
        return (
            <section className={classNames.section}>
            <div className='mt-20 mb-20 md:mt-24 md:mb-24 max-w-[1144px] mx-auto'>
                <div className='flex flex-row items-center'>
                    <p className='text-4xl font-semibold'>Outsourcing</p>
                </div>
                <div>
                    <p className='text-xl mb-8'>Reduce labor costs. Improve efficiency</p>
                </div>
                
                
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-12 my-7'>
                    <Card className="max-w-[456px] h-[300px] shadow-md">
                        <Skeleton className="rounded-lg top-1 my-4 mx-4 w-1/3">
                            <div className="h-4 lg:w-[300px] rounded-lg bg-default-300"></div>
                        </Skeleton>
                            <div className="space-y-3">
                        <Skeleton className="rounded-lg top-1 mb-4 mx-4">
                            <div className="h-7 lg:w-[456px] rounded-lg bg-default-300"></div>
                        </Skeleton>
                            <Skeleton className="rounded-lg left-0 bottom-0 mx-4 mb-4">
                            <div className="h-[150px] rounded-lg bg-default-200"></div>
                            </Skeleton>
                            <Skeleton className="rounded-lg grid grid-cols-4 mx-4 mb-4">  
                                <div className="h-7 rounded-lg bg-default-300"></div>
                            </Skeleton>
                            </div>
                    </Card>
                    <Card className="max-w-[456px] h-[300px] shadow-md">
                        <Skeleton className="rounded-lg top-1 my-4 mx-4 w-1/3">
                            <div className="h-4 lg:w-[300px] rounded-lg bg-default-300"></div>
                        </Skeleton>
                            <div className="space-y-3">
                        <Skeleton className="rounded-lg top-1 mb-4 mx-4">
                            <div className="h-7 lg:w-[456px] rounded-lg bg-default-300"></div>
                        </Skeleton>
                            <Skeleton className="rounded-lg left-0 bottom-0 mx-4 mb-4">
                            <div className="h-[150px] rounded-lg bg-default-200"></div>
                            </Skeleton>
                            <Skeleton className="rounded-lg grid grid-cols-4 mx-4 mb-4">  
                                <div className="h-7 rounded-lg bg-default-300"></div>
                            </Skeleton>
                            </div>
                    </Card>
                </div>
                
                    

                    <div className='font-normal text-sm'>
                        <Accordion className='max-w-[1144px]'>
                            <AccordionItem className="max-w-[940px]" classNames={{ title: "text-[#1D65C1] text-base", content: "text-[#1D1D1D]" }} title="Don't know what outsourcing is?" key="1" aria-label="Accordion 1">
                                {defaultContent}
                            </AccordionItem>
                        </Accordion>
                    </div>
            </div>
            
        </section>
        );
    }

    return (
        <section className={classNames.section}>
            <div className='mt-20 mb-20 md:mt-24 md:mb-24 max-w-[1144px] mx-auto'>
                <div className='flex flex-row items-center'>
                    <p className='text-4xl font-semibold'>Outsourcing</p>
                </div>
                <div>
                    <p className='text-xl mb-8'>Reduce labor costs. Improve efficiency</p>
                </div>
                
                
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-12 my-7'>
                    {outsourcingCards.map((card) => (
                        <OutsourcingCard key={card.id} subtitle={card.subtitle} title={card.title} image={card.image} quote={card.quote} extra={card.extra} route={card.route} visible={card.visible} />
                    ))}
                </div>
                
                    

                    <div className='font-normal text-sm'>
                        <Accordion className='max-w-[1144px]'>
                            <AccordionItem className="max-w-[940px]" classNames={{ title: "text-[#1D65C1] text-base", content: "text-[#1D1D1D]" }} title="Don't know what outsourcing is?" key="1" aria-label="Accordion 1">
                                {defaultContent}
                            </AccordionItem>
                        </Accordion>
                    </div>
            </div>
            
        </section>


        
    )
}

export default Outsourcing