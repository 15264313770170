import React from 'react'

function AAInformation() {
    return (
        <section className='bg-[#6650d527] text-[#1D1D1D] px-6 flex items-center justify-center m-auto'>
            <div className='mt-20 mb-20 md:mt-24 md:mb-24 max-w-[1044px] mx-auto'>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
                    <div>

                    </div>
                    <div>
                        <p className="text-4xl lg:text-6xl font-semibold">
                            Take Control Over Your <span className='text-[#6650d5]'>Learning</span>
                            </p>
                        <p className='mt-4'>Courses designed to fit your schedule. Learn at your own 
                            pace and revisit materials whenever you like. There are no mandatory 
                            courses; choose the ones that suit your needs.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AAInformation