import React, { useContext, useState } from "react";
import { Input, Button, SelectItem, Select, Textarea } from "@nextui-org/react";
import { StepperContext } from "../Context/StepperContext";

export default function Message({ handleClick, currentStep, steps }) {
  const { formData, updateFormData } = useContext(StepperContext);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(formData);
    updateFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    //put here the messages for the inputs with key like input id or name
    const validationRules = {
      CustomMessage: "Contact message is required",
    };

    const newErrors = {};

    for (const [field, errorMessage] of Object.entries(validationRules)) {
      if (!formData[field]) {
        newErrors[field] = "This field is required";
      }
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0; // Retorna true si no hay errores
  };

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleClick("next");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="my-6 text-3xl font-semibold">Custom message</p>
      {/**custom message*/}
      <Textarea
        maxRows={5}
        type="text"
        label="Custom message"
        placeholder="Tell us... why do you want to be part of us or write something that you want to say about your request"
        onChange={handleChange}
        id="CustomMessage"
        name="CustomMessage"
        value={formData.CustomMessage}
        isRequired
        isInvalid={errors.CustomMessage ? true : false}
        errorMessage={errors.CustomMessage}
      />
      <div className="container flex justify-between my-4">
        <Button
          onClick={() => handleClick()}
          className={`bg-[#FDFFFC] text-[#1D1D1D] px-4 py-2 rounded-full 
font-semibold cursor-pointer border-1 border-[#1D1D1D] hover:text-[#FDFFFC] hover:bg-[#1D1D1D] transition duration-200 ease-in-out ${
            currentStep === 1
              ? "opacity-50 cursor-not-allowed"
              : "cursor-pointer"
          }`}
        >
          Back
        </Button>

        <Button
          type="submit"
          className="bg-[#1D65C1] px-4 py-2 rounded-full 
font-medium  border-1 border-[#1D65C1] text-[#FDFFFC] cursor-pointer"
        >
          {currentStep === steps.length ? "Confirm" : "Next"}
        </Button>
      </div>
    </form>
  );
}
