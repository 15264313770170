import React, {useEffect, useState} from 'react';
import '../index.css';

import { TabTitle } from "./dynamicTitles";

import { HeroObjects } from './data';
import HeroElements from './HeroElements';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import {collection, getDocs} from "firebase/firestore";
import {db} from "../firebase/config";
import { Skeleton } from '@nextui-org/react';


function Hero() {


    const [heros, setHeros] = useState([])
    const [loading, setLoading] = useState(true); // INITIAL STATE

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const getHeros = async () => {
            try {
                // VERIFY LOCAL DATA
                if (heros.length === 0) {
                    // console.log("Fetching from server...");
                    const querySnapshot = await getDocs(collection(db, "HeroSection"), {
                        signal,
                    });
                    const docs = [];
                    querySnapshot.forEach((doc) => {
                        docs.push({ ...doc.data(), id: doc.id });
                    });
                    console.log("Data fetched from server:", docs);
                    setHeros(docs);
                } else {
                    // console.log("Using local data:", products);
                }
                setLoading(false); // END LOAD
            } catch (error) {
                // console.error("ERROR fetching data:", error);
            }
        };

        getHeros();

        return () => {
            // REQUEST CANCELED IF COMPONENT UNMOUNTED
            // console.log("Aborting request...");
            controller.abort();
        };
    }, [heros]); // DEPENDENCY TO AVOID UNNECESSARY REQUEST

    TabTitle('Astrobie');

    
    if(loading){
        return (
        
            <section name='hero' className='bg-[#060606] m-auto text-[#FDFFFC] py-0 md:py-5'>
                <Skeleton className='bg-[#060606] w-full h-[750px] md:h-[438px] p-0 m-0'>
                <div className='md:mt-5 mt-0 items-center justify-center lg:max-w-[1044px] xl:max-w-[1144px] 2xl:max-w-[1244px] mx-auto'/>
                 </Skeleton>
            </section>
        )
    }
    return (
        
        <section name='hero' className='bg-[#060606] m-auto text-[#FDFFFC] py-0 md:py-5'>
            <div className='md:mt-5 mt-0 items-center justify-center lg:max-w-[1044px] xl:max-w-[1144px] 2xl:max-w-[1244px] mx-auto'>
                
                    <Swiper
                        style={{
                            "--swiper-pagination-color": "#1D65C1",
                            "--swiper-pagination-bullet-inactive-color": "#989997",
                            "--swiper-pagination-bullet-inactive-opacity": "1",
                            "--swiper-pagination-bullet-size": "8px",
                            "--swiper-pagination-bullet-horizontal-gap": "6px",
                        }}
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                        }}
                        /* pagination={{
                        clickable: true,
                        }} */
                        navigation={false}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                        >

                        {heros.map((item) => (
                            <SwiperSlide key={item.id}>
                                <HeroElements id={item.title} heading={item.heading} title={item.title} subtitle={item.subtitle} image={item.image} button={item.button} route={item.route} classNames={item.classNames}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                        </div>
        </section>
    )
}

export default Hero

