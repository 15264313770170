import React from "react";
import { Card, CardBody, Image} from "@nextui-org/react";

const Learning = () => {
    const subjects = [
        {
            title: "Science",
            color: "text-[#5AB489]",
            image: "https://images.pexels.com/photos/8539809/pexels-photo-8539809.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
            title: "Technology",
            color: "text-[#6650D5]",
            image: "https://images.pexels.com/photos/6804610/pexels-photo-6804610.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
            title: "Engineering",
            color: "text-[#FFA664]",
            image: "https://images.pexels.com/photos/257886/pexels-photo-257886.jpeg",
        },
        {
            title: "Mathematics",
            color: "text-[#FF6464]",
            image: "https://images.pexels.com/photos/5915236/pexels-photo-5915236.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
    ];

    return (
        <section className="px-6 m-auto text-[#1D1D1D] w-[100%] flex items-center justify-center">
            <div className="flex w-full flex-col max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24">
                <div className="text-4xl font-semibold mb-8">
                    <span className="text-[#1D1D1D]">
                        Learn On Demand Skills
                    </span>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-12">
                    {subjects.map((subject, index) => (
                        <Card
                            key={index}
                            className={`w-full h-[300px] mt-5 shadow-lg`}>
                            <CardBody className="m-0 p-0 flex items-end justify-end">
                                <Image
                                    removeWrapper
                                    alt="learning path"
                                    className="z-10 w-full h-full object-cover"
                                    src={subject.image}
                                />
                                <p className={`text-3xl md:text-4xl font-semibold absolute z-20 p-5 text-[#FDFFFC]`}>{subject.title}</p>
                            </CardBody>
                        </Card>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Learning;
