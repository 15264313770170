import React from 'react'

function AAHowItWorks() {
    return (
        <section className='bg-[#ff646426] text-[#1D1D1D] flex items-center justify-center m-auto'>
            <div className='mt-20 mb-20 md:mt-24 md:mb-24 max-w-[1044px] mx-6'>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
                    <div>

                    </div>
                    <div>
                        <p className="text-4xl lg:text-6xl font-semibold mx-auto lg:mb-4"><span className='text-[#ff6464]'>Professional</span> Content</p>
                        <p>We partner with universities to offer high-quality courses. Upon completion, learners receive a certificate of achievement.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AAHowItWorks