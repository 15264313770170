import React from "react";
import { Link } from 'react-router-dom';
import {Button} from "@nextui-org/react";



function AboutUs() {
    return (
        <section name='about' className='p-0 m-0 bg-[#060606] flex-col flex-wrap items-center flex justify-center w-full lg:h-[540px]'>
            {/* max-w-[1144px] */}
            <div className="grid grid-cols-1 lg:grid-cols-2 items-center max-w-7xl mt-20 md:mt-24 md:mb-24">
                <div className="order-2 justify-end">
                    <img className="md:w-full lg:h-[540px] md:object-cover relative" alt="About Us Media" src='/images/Logo_1_Astrobie.png'></img>
                </div>
                <div className="lg:order-2 text-white px-4 py-10 lg:px-14 lg:py-28  md:text-base text-justify ">
                    <h1 className="text-4xl font-semibold mb-4 text-white">About Us</h1>
                    <p className='text-sm mb-2'>
                        Astrobie LLC is a Chicago-based technology company committed to delivering cutting-edge solutions and services. Founded in 2024, we have been at the forefront of technological innovation, helping businesses navigate the digital landscape.
                    </p>
                    <p className="text-sm mb-2">
                        At Astrobie, we believe in the power of technology to transform lives. We partner with our clients, understanding their unique needs and challenges, and deliver solutions that empower them to achieve their goals.
                    </p>
                    <p className="text-sm mb-6">
                        Our commitment to quality, innovation, and customer satisfaction has made us a trusted technology partner for businesses across industries. We are proud to be part of Chicago's vibrant tech scene and look forward to continuing to serve our clients with excellence.
                    </p>
                    <div className="flex flex-row">
                        {/*<Link to="about-us">
                            <Button className="bg-[transparent] rounded-full border-1 border-[#FDFFFC] text-[#FDFFFC] hover:bg-[#FDFFFC] hover:text-[#1D1D1D]">
                                <p>Get To Know Us</p>
                            </Button>
                        </Link>*/}
                        <Link to="contact-us">
                            <Button className="bg-[#1D65C1] rounded-full text-[#FDFFFC]"> {/* ml-4 */}
                                <p>Contact Us</p>
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )

}
export default AboutUs