import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Image,
  Divider,
} from "@nextui-org/react";
import { Link } from 'react-router-dom';
import BasicModal from "../BasicModal";
function Details() {
  return (
      <section className="bg-[#FDFFFC] px-6 m-auto text-[#1D1D1D] w-[100%] flex items-center justify-center">
        <div className="max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24">
          <div className="text-4xl font-semibold mb-8">
            <span className="text-[#1D1D1D]">Resources</span>
          </div>

          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <Card className="bg-[#F4F6F3] lg:w-[400px] xl:w-[550px] min-w-[300px] lg:h-[300px] h-[340px] mt-5 shadow-none">
              <CardHeader className='flex-col items-start'>
                <p className='text-sm md:text-medium font-semibold uppercase text-[#5AB489]'>Be part of our family</p>
                <p className='text-xl md:text-2xl xl:text-3xl font-semibold'>For Volunteers</p>
                <p className='text-xs font-normal'>Astrobie Academy is the result and legacy of amazing individuals</p>
              </CardHeader>
              <CardBody className="overflow-visible flex justify-end mb-0 pb-0">
                <Image
                    removeWrapper
                    alt="Volunteers"
                    width={120}
                    className="z-10 w-[130px] absolute object-cover"
                    src={"/images/For_Volunteers.svg"}
                />
              </CardBody>
              <CardFooter className="bottom-0 z-10 h-16 justify-end flex pt-0 mt-0">
                {/* Call the  modal and pass the button to action it */}
                <BasicModal
                    size="xl"
                    header={() => <p className="text-2xl font-medium text-[#1D1D1D]"><span className="font-semibold text-[#5AB489]">Be Part of the Change: </span>Volunteer Opportunities at Astrobie Academy </p>}
                    body={() => (
                        <>
                          <p className="text-sm">
                            Our volunteers play a crucial role in driving our mission forward, helping us provide free, high-quality educational resources to learners around the world.
                          </p>
                          <Divider className="my-4" />
                          <p className="text-sm font-semibold">How you can contribute:</p>
                          <ul class="list-disc ml-2">
                            <li className="text-sm"><span className="font-semibold text-[#1D64C1] marker:text-[#1D64C1]">Ambassadors: </span>Represent and promote Astrobie Academy's mission in your local communities. Build partnerships with schools, universities, and other organizations to expand Astrobie Academy's reach.</li>
                            <li className="text-sm"><span className="font-semibold text-[#1D64C1] marker:text-[#1D64C1]">IT Team: </span>Assist with technical support, website maintenance, and development of new features.</li>
                            <li className="text-sm"><span className="font-semibold text-[#1D64C1] marker:text-[#1D64C1]">Marketing: </span>Help create and execute marketing strategies and engage with the community.</li>
                            <li className="text-sm"><span className="font-semibold text-[#1D64C1] marker:text-[#1D64C1]">Event Coordinators: </span>Plan and organize virtual or in-person events, workshops, and webinars.</li>
                          </ul>
                        </>
                    )}
                    footer={() => (
                        <>
                          <Link to="mailto:volunteering@astrobie.com">
                            <Button className="rounded-full bg-[#FDFFFC] text-[#1D1D1D] border-1 border-[#1d1d1d6c] hover:bg-[#1D1D1D] hover:text-[#FDFFFC] mx-2">
                              Contact Us
                            </Button>
                          </Link>
                          <Link to="/volunteer">
                            <Button className="rounded-full bg-[#1D65C1] text-[#FDFFFC] mx-2">
                              Register
                            </Button>
                          </Link>
                        </>
                    )}
                    button={(handleClick) => (
                        <Button
                            isIconOnly
                            className="bg-[#1D65C1] hover:bg-[#2B51CC] rounded-full text-[#FDFFFC] text-lg"
                            onClick={handleClick}
                        >
                          &#43;
                        </Button>
                    )}
                    classNames={{ all: "" }}
                />
              </CardFooter>
            </Card>
            <Card className="bg-[#F4F6F3] lg:w-[400px] xl:w-[550px] min-w-[300px] lg:h-[300px] h-[340px] mt-5 shadow-none">
              <CardHeader className='flex-col items-start'>
                <p className='text-sm md:text-medium font-semibold uppercase text-[#6650D5]'>Spread our mission further</p>
                <p className='text-xl md:text-2xl xl:text-3xl font-semibold'>For Colleges and Universities</p>
                <p className='text-xs font-normal'>Become a member, contribute, and get access to exclusive perks tailored to our partners</p>
              </CardHeader>
              <CardBody className="overflow-visible flex justify-end mb-0 pb-0">
                <Image
                    removeWrapper
                    alt="Schools"
                    width={120}
                    src={"/images/For_Colleges_and_Universities.svg"}
                />
              </CardBody>
              <CardFooter className="bottom-0 z-10 h-16 justify-end flex pt-0 mt-0">
                {/* Call the  modal and pass the button to action it */}
                <BasicModal
                    size="xl"
                    header={() => <p className="text-2xl font-medium text-[#1D1D1D]">Shaping Tomorrow's <span className="font-semibold text-[#6650D5]">Leaders</span></p>}
                    body={() => (
                        <>
                          <p className="text-sm">
                            Together, we can create a more inclusive and equitable
                            educational landscape, empowering the next generation of
                            leaders and thinkers. Join us in this meaningful journey
                            to make a lasting impact on education and society.
                          </p>
                          <Divider className="my-4" />
                          <p className="text-sm font-semibold">
                            How your institution can contribute:
                          </p>
                          <ul class="list-disc ml-2">
                            <li className="text-sm">
                        <span className="font-semibold text-[#1D64C1] marker:text-[#1D64C1]">
                          Donating Educational Resources:{" "}
                        </span>
                              Institutions can donate books, courses, software
                              licenses, and other educational materials.
                            </li>
                            <li className="text-sm">
                        <span className="font-semibold text-[#1D64C1] marker:text-[#1D64C1]">
                          Student Internships and Volunteering:{" "}
                        </span>
                              Encourage students to gain valuable experience by
                              interning or volunteering with Astrobie Academy.
                            </li>
                            <li className="text-sm">
                        <span className="font-semibold text-[#1D64C1] marker:text-[#1D64C1]">
                          Financial Contributions:{" "}
                        </span>
                              Monetary donations are crucial for sustaining and
                              expanding our programs. Contributions can help us
                              develop new courses, improve our platform, and reach
                              more students globally.
                            </li>
                            <li className="text-sm">
                        <span className="font-semibold text-[#1D64C1] marker:text-[#1D64C1]">
                          Hosting Hackathons and Activities:{" "}
                        </span>
                              Allow Astrobie Academy to organize hackathons,
                              workshops, and other educational activities on your
                              campus.
                            </li>
                          </ul>
                        </>
                    )}
                    footer={() => (
                        <>
                          <Link to="mailto:volunteering@astrobie.com">
                            <Button className="rounded-full bg-[#FDFFFC] text-[#1D1D1D] border-1 border-[#1d1d1d6c] hover:bg-[#1D1D1D] hover:text-[#FDFFFC] mx-2">
                              Contact Us
                            </Button>
                          </Link>
                          <Link to="/university">
                            <Button className="rounded-full bg-[#1D65C1] text-[#FDFFFC] mx-2">
                              Register
                            </Button>
                          </Link>
                        </>
                    )}
                    button={(handleClick) => (
                        <Button
                            isIconOnly
                            className="bg-[#1D65C1] hover:bg-[#2B51CC] rounded-full text-[#FDFFFC] text-lg"
                            onClick={handleClick}
                        >
                          &#43;
                        </Button>
                    )}
                    classNames={{ all: "" }}
                />
              </CardFooter>
            </Card>
          </div>
        </div>
      </section>
  );
}
export default Details;