import React from "react";
import { Card, CardHeader, CardBody } from "@nextui-org/react";
import { Check, Cancel } from "../resources/Icons";

function Support() {
  return (
    <section className="bg-[#FDFFFC] px-6 m-auto text-[#1D1D1D] w-[100%] flex items-center justify-center">
      <div className="max-w-[1144px] mt-20 mb-24 md:mt-24 md:mb-24 items-center justify-center">
        <div>
          <span className="text-4xl font-semibold bg-clip-text text-[#1D1D1D]">
            Support Available
          </span>
          {/* Talk about the challenges that small businesses encounter */}
          <p className="text-xl mb-8">
            You don't have to be alone during the process.
          </p>
        </div>

        <div className="grid grid-cols-3 gap-6">
          {/*FREE CARD*/}
          <Card className="col-span-1 max-md:col-span-3">
            {/*Price Section */}
            <CardHeader className="flex-col p-0">
              <Card className="flex items-center justify-center h-48 w-full p-6 bg-[#CD8987] text-[#F4F6F3] shadow-lg">
                <p className="font-normal text-2xl">Start-Up</p>
                <p className="font-semibold text-6xl">Free</p>
              </Card>
            </CardHeader>
            {/* Features List */}
            <CardBody className="overflow-visible py-2">
              <div className="p-3">
                <ul className=" mt-2 text-[#1D1D1D]">
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Check className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 font-medium">
                      IT specialist advice/training
                    </p>
                  </li>
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Cancel className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 text-[#403F4A]">
                      Manage your Astrobie account
                    </p>
                  </li>
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Cancel className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 text-[#403F4A]">IT Helpdesk</p>
                  </li>
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Cancel className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 text-[#403F4A]">
                      Get a brand new UI design each year
                    </p>
                  </li>
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Cancel className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 text-[#403F4A]">24/7 support</p>
                  </li>
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Cancel className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 text-[#403F4A]">
                      Get marketing mockups twice a year
                    </p>
                  </li>
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Cancel className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 text-[#403F4A]">
                      Cibersecurity measurements
                    </p>
                  </li>
                  {/* <li className='grid grid-cols-10 gap-1 items-center my-2'>Antivirus protection for your devices (up to 5 devices)</li> */}
                </ul>
              </div>
            </CardBody>
          </Card>

          {/*BASIC CARD*/}
          <Card className="col-span-1 max-md:col-span-3">   
            {/* Price Section */}
            <CardHeader className="flex-col p-0">
              <Card className="flex items-center justify-center h-48 w-full p-6 bg-[#1d65c1] text-[#F4F6F3] shadow-lg">
                <p className="font-normal text-2xl">Basic</p>
                <p className="font-semibold text-6xl">$59.99</p>
                <p className="font-light">PER MONTH</p>
              </Card>
            </CardHeader>
            {/* Features List */}
            <CardBody className="overflow-visible py-2">
              <div className="p-3">
                <ul className=" list-none mt-2 text-[#1D1D1D]">
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Check className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 font-medium">
                      IT specialist advice/training
                    </p>
                  </li>
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Check className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 font-medium">
                      Manage your Astrobie account
                    </p>
                  </li>
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Check className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 font-medium">IT Helpdesk</p>
                  </li>
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Check className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 font-medium">
                      Get a brand new UI design each year
                    </p>
                  </li>
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Cancel className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 text-[#403F4A]">24/7 support</p>
                  </li>
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Cancel className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 text-[#403F4A]">
                      Get marketing mockups twice a year
                    </p>
                  </li>
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Cancel className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 text-[#403F4A]">
                      Cibersecurity measurements
                    </p>
                  </li>
                  {/* <li className='grid grid-cols-10 gap-1 items-center my-2'>Antivirus protection for your devices (up to 5 devices)</li> */}
                </ul>
              </div>
            </CardBody>
          </Card>

          {/* PRO CARD */}
          <Card className="col-span-1 max-md:col-span-3">
            {/*Price Section */}
            <CardHeader className="flex-col p-0">
              <Card className="flex items-center justify-center h-48 w-full p-6 bg-[#EEA25C] text-[#F4F6F3] shadow-lg">
                <p className="font-normal text-2xl">Pro</p>
                <p className="font-semibold text-6xl">$99.99</p>
                <p className="font-light">PER MONTH</p>
              </Card>
            </CardHeader>
            {/*Features list */}
            <CardBody className="overflow-visible py-2">
              <div className="p-3">
                <ul className=" list-none mt-2 text-[#1D1D1D]">
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Check className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 font-medium">
                      IT specialist advice/training
                    </p>
                  </li>
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Check className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 font-medium">
                      Manage your Astrobie account
                    </p>
                  </li>
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Check className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 font-medium">IT Helpdesk</p>
                  </li>
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Check className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 font-medium">
                      Get a brand new UI design each year
                    </p>
                  </li>
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Check className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 font-medium">24/7 support</p>
                  </li>
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Check className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 font-medium">
                      Get marketing mockups twice a year
                    </p>
                  </li>
                  <li className="grid grid-cols-10 gap-1 items-center my-2">
                    <Check className="col-span-1" />
                    <p className="col-span-9 max-lg:ms-3 font-medium">
                      Cibersecurity measurements
                    </p>
                  </li>
                  {/* <li className='grid grid-cols-10 gap-1 items-center my-2'>Antivirus protection for your devices (up to 5 devices)</li> */}
                </ul>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </section>
  );
}

export default Support;
