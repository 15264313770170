import { motion } from "framer-motion";
import { FiMapPin } from "react-icons/fi";

export const AAHeroSection = () => {
    return (
        <section className='bg-[#FDFFFC] text-[#1D1D1D] w-[100%] flex-col items-center justify-center'>
            <div className='mt-20 mb-20 md:mt-24 md:mb-24 px-6 mx-auto flex-column content-center static gap-8'>
                <div className="bg-[#FDFFFC]">
                    <Country/>
                </div>
            </div>
        </section>
    );
};


    
// COUNTRIES SECTION
const Country = () => {
    return (
        <section
            id="countries"
            className="mx-auto max-w-5xl px-4 text-[#1D1D1D]"
        >
            <motion.h1
                viewport={{once: true}}
                initial={{ y: 48, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ ease: "easeInOut", duration: 0.75 }}
                className="mb-20 text-4xl font-semibold"
            >
                Unity In Diversity
            </motion.h1>
            <CountryItem country="Mexico" continent="North America" city="Moroleon"/>
            <CountryItem country="United States" continent="North America" city="Chicago"/>
            <CountryItem country="Kenya" continent="Africa" city="Nairobi"/>
        </section>
    );
};
const CountryItem = ({ country, continent, city }) => {
    return (
        <motion.div
            viewport={{once: true}}
            initial={{ y: 48, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ ease: "easeInOut", duration: 0.75 }}
            className="mb-14 flex items-center justify-between border-b border-zinc-800 px-3 pb-6 text-[#1D1D1D]"
        >
            <div>
                <p className="text-2xl text-[#1D1D1D] font-medium">{country}</p>
                <p className='text-base text-[#1d1d1d98]'>{continent}</p>
            </div>
            <div className="flex items-center gap-1.5 text-end text-sm  text-[#1d1d1d8e]">
                <p>{city}</p>
                <FiMapPin/>
            </div>
        </motion.div>
    );
};