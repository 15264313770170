import React, { useState } from "react";
import Stepper from "./Stepper";
//import StepperControl from "./StepperController";
import { StepperContext } from "./Context/StepperContext";
import ContactInfo from "./Steps/ContactInfo";
import Complete from "./Steps/Complete";

//firebase
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../firebase/config";

import EmpoweringEducation from "./Steps/Empowering";
import SchoolInformation from "./Steps/SchoolInformation";
import Message from "./Steps/Message";

// Form component to make a enterprise registartion

export default function UniversityForm() {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    schoolName: "",
    schoolPhysicalAddress: "",
    schoolCountry: "",
    schoolPhoneNumber: "",
    schoolWebsiteURL: "",
    ContactFirstName: "",
    ContactLastName: "",
    Contactgender: "",
    ContactemailAddress: "",
    ContactemailAddressConfirm: "",
    ContactphoneNumber: "",
    ContactRole: "",
    CustomMessage: "",
  });

  const steps = ["Introduce", "School ", "Contact ", "Message", "Complete"];

  const updateFormData = (newData) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  };

  const saveData = async () => {
    try {
      const dataRef = collection(db, "university");
      await addDoc(dataRef, {
        ...formData,
        createdAt: new Date(),
      });
      console.log("Datos guardados correctamente.");
      setFormData({
        schoolName: "",
        schoolPhysicalAddress: "",
        schoolCountry: "",
        schoolPhoneNumber: "",
        schoolWebsiteURL: "",
        ContactFirstName: "",
        ContactLastName: "",
        Contactgender: "",
        ContactemailAddress: "",
        ContactemailAddressConfirm: "",
        ContactphoneNumber: "",
        ContactRole: "",
        CustomMessage: "",

        // Reinicia otros campos según sea necesario
      });
    } catch (error) {
      console.error("failed to save the data", error);
      console.log("No jala.");
    }
  };
  const displayStep = (step) => {
    switch (step) {
      case 1:
        return (
          <EmpoweringEducation
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps}
          />
        );
      case 2:
        return (
          <SchoolInformation
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps}
          />
        );

      case 3:
        return (
          <ContactInfo
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps}
          />
        );
      case 4:
        return <Message
        handleClick={handleClick}
        currentStep={currentStep}
        steps={steps}
      />;
      case 5:
        return <Complete />;
      default:
        return null;
    }
  };

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    //check if steps are within bound
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);

    if (newStep === steps.length) {
      console.log(steps.length);
      saveData();
    }
  };

  return (
    <section className="bg-[#F4F6F3] text-[#1D1D1D] px-6 flex items-center justify-center m-auto">
      <div className="mt-20 mb-20 md:mt-24 md:mb-24 max-w-[1144px] mx-80 w-full">
        <div className="text-4xl font-semibold">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#1D1D1D] to-[#1D1D1D]">
            Universities and Colleges form
          </span>
        </div>
        <div className="bg-[#FFFCFD] mt-20 mb-20 md:mb-20 max-w-[1144px] w-[90vw] mx-auto shadow-xl rounded-2xl p-5 md:p-20 py-2">
          {/*Stepper*/}
          <div className="grid md:px-6 justify-items-stretch horizontal">
            <Stepper steps={steps} currentStep={currentStep - 1} />
          </div>
          <div className="pb-0 mt-10 mb-10 md:px-6">
            {/*Display components*/}
            <StepperContext.Provider
              value={{
                formData,
                updateFormData,
              }}
            >
              {displayStep(currentStep)}
            </StepperContext.Provider>
          </div>
        </div>
      </div>
    </section>
  );
}
