import React from 'react';

import TestimonialsCard from './TestimonialsCard';
import { TestimonialsData } from './data';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Testimonials = () => {
    return (
        <section name='Testimonials' className='bg-[#FDFFFC] text-[#1D1D1D] py-2 w-[100%] flex-col items-center justify-center'>
            <div className='mt-20 mb-24 md:mt-24 md:mb-24 flex-column content-center static gap-8 '>

                <div className="text-[#1D1D1D] max-w-[1144px] mb-16 px-6 mx-auto">
                    <span className="text-4xl font-semibold">Testimonials</span>
                    <p className="text-xl">What our team have to say</p>
                </div>
                
                <Swiper
                    style={{
                        "--swiper-pagination-color": "#1D65C1",
                        "--swiper-pagination-bullet-inactive-color": "#989997",
                        "--swiper-pagination-bullet-inactive-opacity": "1",
                        "--swiper-pagination-bullet-size": "8px",
                        "--swiper-pagination-bullet-horizontal-gap": "6px",
                    }}
                    centeredSlides={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: true,
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 20
                        },
                        400: {
                            slidesPerView: 1.3,
                            spaceBetween: 40,
                        },
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        735: {
                            slidesPerView: 2.5,
                            spaceBetween: 60,
                        },
                        1024: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                        1124: {
                            slidesPerView: 2.2,
                            spaceBetween: 10,
                        },
                        // Add a larger breakpoint with max space
                        1440: {
                            slidesPerView: 2.5,
                            spaceBetween: 20,
                        },
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={false}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mx-auto w-full 2xl:max-w-[1400px]"
                >
                    {TestimonialsData.map((item) => (
                        <SwiperSlide key={item.id} >
                            <TestimonialsCard
                                id={item.title}
                                name={item.name}
                                title={item.title}
                                text={item.text}
                                image={item.image}
                                country={item.country}
                                university={item.university}
                                color={item.color}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
};

export default Testimonials;