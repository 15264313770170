import React from 'react'
import { Button, Tooltip } from "@nextui-org/react";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { Pagination, FreeMode } from 'swiper/modules';
import { AcademyData } from "./data";
import AcademyCard from "./AcademyCard";
import { Link } from 'react-router-dom';

function AstrobieAcademy() {

    return (

        <section name='global' className='bg-[#F4F6F3] text-[#1D1D1D] py-2 w-[100%] flex-col items-center justify-center'>
            <div className='mt-20 mb-24 md:mt-24 md:mb-24 px-6 mx-auto flex-column content-center static gap-8'>
                <div className="text-[#1D1D1D] max-w-[1144px] mb-16 px-6 mx-auto">
                    <span className="text-4xl md:text-4xl font-semibold">
                        Astrobie Academy
                    </span>
                    <p className='text-xl'>Made to best serve the world</p>
                </div>

                <div className="">
                    <Swiper
                        style={{
                            "--swiper-pagination-color": "#1D65C1",
                            "--swiper-pagination-bullet-inactive-color": "#989997",
                            "--swiper-pagination-bullet-inactive-opacity": "1",
                            "--swiper-pagination-bullet-size": "8px",
                            "--swiper-pagination-bullet-horizontal-gap": "6px",
                        }}
                        modules={[FreeMode, Pagination]}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 10
                            },
                            400: {
                                slidesPerView: 1.2,
                                spaceBetween: 40,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            735: {
                                slidesPerView: 2.5,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 3.3,
                                spaceBetween: 30,
                            },
                            1124: {
                                slidesPerView: 3.5,
                                spaceBetween: 30,
                            }
                        }}
                        centeredSlides={true}
                        freeMode={true}
                        pagination={{
                            clickable: true
                        }}
                        className='mx-auto w-full 2xl:max-w-[1400px]'
                    >
                        {AcademyData.map((item) => (
                            <SwiperSlide key={item.id}>
                                <AcademyCard
                                    id={item.title}
                                    classNames={{ title: 'text-' + item.color }}
                                    title={item.title}
                                    content={item.content}
                                    body={item.body}
                                    image={item.image}
                                    imageSize="300px" />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                <div className='flex justify-center items-center'>

                    <Link to="/academy">
                        <Button className="bg-[#1D65C1] rounded-full text-[#FDFFFC] flex justify-center mb-6 mt-4">
                            Launch Astrobie Academy
                        </Button>
                    </Link>
                </div>
                <div className=' max-w-[1144px] px-6 mx-auto'>
                    <div className='flex gap-3 my-2'>
                        <Tooltip content="Mexico">
                            <img src='/images/mexico.png' alt='Mexico' className='h-6 w-6 rounded-full' />
                        </Tooltip>
                        <Tooltip content="United States">
                            <img src='/images/usa.png' alt='USA' className='h-6 w-6 rounded-full' />
                        </Tooltip>
                        <Tooltip content="Kenya">
                            <img src='/images/kenya.png' alt='Kenya' className='h-6 w-6 rounded-full' />
                        </Tooltip>
                    </div>


                    <div className='text-sm'>
                        <p>Made with &nbsp;
                            <span className='text-[#CE1126]'>&#10084;</span>
                            &nbsp; by people from the world sharing the same mission
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AstrobieAcademy