import React from "react";
import {
    Card,
    CardHeader,
    CardFooter,
    Image,
    Button,
    CardBody,
    Divider,
} from "@nextui-org/react";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import BasicModal from "../BasicModal";

export default function AcademyCard(props) {
    const className = twMerge(
        "max-w-[350px] min-w-[300px] h-[300px] mb-14 mt-5 hover:scale-105",
        props.className
    );
    const classNames = {
        header: twMerge("block", props.classNames.header),
        title: twMerge(
            "text-xs font-semibold text-[#EE964B] uppercase",
            props.classNames.title
        ),
        content: twMerge(
            "text-[#1D1D1D] font-semibold text-2xl",
            props.classNames.content
        ),
        image: twMerge("z-0 h-[120px] w-[120px] text-right"),
        footer: twMerge("block bottom-0 z-10 h-16", props.classNames.footer),
        button: twMerge(
            "bg-[#1D65C1] hover:bg-[#2b51cc] text-[#FDFFFC] text-lg",
            props.classNames.button
        ),
        body: twMerge("overflow-visible flex justify-end", props.classNames.button),
    };

    const buttonProps = {
        isIconOnly: true,
        "aria-label": "Info",
        radius: "full",
        size: "sm",
    };

    return (
        <Card key={props.id} className={className} {...props}>
            <CardHeader className={classNames.header}>
                <p className={classNames.title}>{props.title}</p>
                <h4 className={classNames.content}>{props.content}</h4>
            </CardHeader>
            <CardBody className={classNames.body}>
                <Image
                    removeWrapper
                    alt={props.title}
                    className={classNames.image}
                    src={props.image}
                />
            </CardBody>
            <CardFooter className={classNames.footer}>
                <BasicModal
                    header={() => (
                        <>
                            <p className={classNames.title}>
                                {props.title}{" "}
                            </p>
                            <p className={classNames.content}>
                                {props.content}
                            </p>
                        </>
                    )}
                    body={() => (
                        <>
                            <Divider className="mb-2"/>
                            <p className="text-sm text-[#1d1d1d]">{props.body}</p>
                        </>
                    )}
                    button={(handleClick) => (
                        <Button
                            {...buttonProps}
                            className={classNames.button}
                            onClick={handleClick}
                        >
                            &#43;
                        </Button>
                    )}
                    classNames={{ all: "" }}
                />
            </CardFooter>
        </Card>
    );
}