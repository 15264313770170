export const ConnectivityData = [
    {
        id: 1,
        title: 'Easy To Operate',
        content: 'A Natural Extencion Of Your Phone',
        image: '',
        color: '[#EE964B]'
    },{
        id: 2,
        title: 'Ready To Go',
        content: 'No App. No Setting Up.',
        image: '',
        color: '[#84828F]'
    },{
        id: 3,
        title: 'Technology On Your Side',
        content: 'High Technology',
        image: '',
        color: '[#CD8987]'
    },{
        id: 4,
        title: 'Only Share Wath You Want',
        content: 'Your Information Remains Private',
        image: '',
        color: '[#94BB9B]'
    },{
        id: 5,
        title: 'Easy To Operate',
        content: 'A Natural Extencion Of Your Phone',
        image: '',
    }
]

export const cardsType = [
    {
      name: "Darkness",
      type: "Plastic",
      image:
        "https://img.freepik.com/foto-gratis/textura-oscura-fondo_23-2147661187.jpg?t=st=1723686302~exp=1723689902~hmac=a94285458bd561b62ef0868eec6abf7f49ce0786082cb02500ad45aed49239e0&w=740",
    },
    {
      name: "White",
      type: "Plastic",
      image:
        "https://img.freepik.com/foto-gratis/textura-papel-blanco_1194-5705.jpg?t=st=1723686408~exp=1723690008~hmac=615bd079e40bf9f3570d76cc3f3d67d23110ce67546c75167d9a3c5adc1f7f91&w=740",
    },
    {
      name: "Fiber carbon",
      type: "Plastic",
      image:
        "https://img.freepik.com/foto-gratis/papel-pintado-rayas-negro_1194-6565.jpg?t=st=1723686525~exp=1723690125~hmac=4373e8b470e15f26aed8305d8ebe5339735f55fcbe2974f3b8d33bd5b0857c23&w=740",
    },
    {
      name: "Navy",
      type: "Plastic",
      image:
        "https://img.freepik.com/foto-gratis/fondo-texturizado-pared-yeso-solido_53876-129487.jpg?t=st=1723686704~exp=1723690304~hmac=92240706212fcf73003002078de0d508ff052a49131ae8d3a55ad37f9d62e115&w=740",
    },
    {
      name: "Golden",
      type: "Stainless Steel",
      image:
        "https://img.freepik.com/foto-gratis/fondo-texturizado_53876-41751.jpg?t=st=1723686818~exp=1723690418~hmac=2c588d13aa9ed873e8a4031ac2291389f1889a26f480afac08e3533e040b607b&w=740",
    },
    {
      name: "Silver",
      type: "Stainless Steel",
      image:
        "https://img.freepik.com/fotos-premium/fondo-textura-metalica-diseno-industrial_707519-27748.jpg?w=826",
    },
    {
      name: "Smoked",
      type: "Stainless Steel",
      image:
        "https://img.freepik.com/foto-gratis/textura-metalica-sucia_1048-4784.jpg?t=st=1723686924~exp=1723690524~hmac=554c23bc1b1932ae11baf75fd90afc72b15fa704ee0141a3a80b4ee7b40c8b85&w=740",
    },
    {
      name: "Oxide",
      type: "Stainless Steel",
      image:
        "https://img.freepik.com/foto-gratis/vista-superior-superficie-metal-oxidado_23-2148796565.jpg?t=st=1723687086~exp=1723690686~hmac=bfe01a164144aba18ab7c257cb3ca9943fd41b400ff9cfff89f5a371f40d087f&w=740",
    },
    {
      name: "Dark Wood",
      type: "Earth Care",
      image:
        "https://img.freepik.com/foto-gratis/diseno-fondo-textura-suelo-madera_53876-108357.jpg?t=st=1723687130~exp=1723690730~hmac=68f5c916fcfd06def05f3368efbab17258484327409e6483e49924c281a8fd51&w=740",
    },
    {
      name: "Light Wood",
      type: "Earth Care",
      image:
        "https://img.freepik.com/foto-gratis/fondo-suelo-textura-madera-beige_53876-129605.jpg?t=st=1723687166~exp=1723690766~hmac=b85865dba1ac255e85b8188bba6e5c21f0f2581f41e49d0d25835485e2c1018f&w=740",
    },
    {
      name: "Recycled",
      type: "Earth Care",
      image:
        "https://img.freepik.com/foto-gratis/textura-papel-vista-superior_23-2149061966.jpg?t=st=1723687271~exp=1723690871~hmac=a7813177d2f677c464a8c7f83a13a9fe0d210b2ae70b07d800300614d51042bc&w=740",
    },
    {
      name: "Glass",
      type: "Earth Care",
      image:
        "https://img.freepik.com/foto-gratis/fotografia-patron-textura-espuma-poliestireno_58702-12012.jpg?t=st=1723687341~exp=1723690941~hmac=0b6321e28e21e37b26d7fb098c977b3ded9cf4f223630c587a5b370614d7f987&w=740",
    },
    {
      name: "Rubber",
      type: "Plastic",
      image:
        "https://img.freepik.com/foto-gratis/fondo-textura-tela-pana-oscura_53876-101965.jpg?t=st=1723687468~exp=1723691068~hmac=adee97fb93bdc06181ebc52f820dd619d5137a07f233ff3be107da219b033763&w=740",
    },
    {
      name: "concrete",
      type: "Plastic",
      image:
        "https://img.freepik.com/foto-gratis/muro-hormigon-gris_53876-90728.jpg?t=st=1723687552~exp=1723691152~hmac=06fe574933bef195f1da281c60e1c09708a4a6f21fcbeb4926bc75d646f35e7e&w=740",
    },
    {
      name: "Esmeralda",
      type: "Stainless Steel",
      image:
        "https://img.freepik.com/foto-gratis/papel-tapiz-fondo-artistico-efecto-semitono-color_58702-9036.jpg?t=st=1723748840~exp=1723752440~hmac=a4af92dd58552c061ccc9475dfc2def3b134cb91f6b6d298dffbe58e0555991e&w=740",
    },
    {
      name: "Formal",
      type: "Stainless Steel",
      image:
        "https://img.freepik.com/foto-gratis/primer-plano-marmol-textura-fondo_53876-64734.jpg?t=st=1723749085~exp=1723752685~hmac=fabd270b0addcb9b09ec0968c88da0b1791ffbe086f54c8a291077f28a7f2240&w=740",
    },
  ];