import React, { useState, useEffect } from "react";
import { Button } from "@nextui-org/react";
import {Link} from "react-router-dom";
import { TabTitle } from "../dynamicTitles";

export default function WAHeroSection( {
                                    title = "Build a website with us",
                                    subtitle = "Don't fall for those who want to manage your website charging you forever and never having access to it. Our technicians will explain you how to manage everything. You started your business to be your own boss, don't wait for somebody else",
                                }) {
                                    
    TabTitle('Web Assistance');


    return (
        <section name="HeroSection" className='bg-[#000000] m-auto text-[#FDFFFC] py-7 md:py-24'>
            <div className='lg:max-w-[1044px] xl:max-w-[1244px] mx-auto'>
                <div className="grid grid-cols-1 mx-6 md:grid-cols-2 gap-2 md:gap-4 items-center justify-center">
                    <div className=''>
                        <p className='text-xl uppercase'>Web Assistance</p>
                        <div className="text-6xl md:text-6xl font-semibold">
                            <span
                                className="text-[#FDFFFC]">
                                {title}
                            </span>
                        </div>
                        {/* <p className='text-sm'>{subtitle}</p> */}
                    </div>
                    <div className="py-2 h-96">
                        <img src="/images/outsourcing_web_assistance.png" alt="Web Assistance" className="md:float-end"/>
                    </div>
                </div>
            </div>
        </section>
    );
}

