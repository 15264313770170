import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Accordion,
  AccordionItem,
  Modal,
  ModalBody,
  ModalFooter,
  Modalanswer,
  ModalHeader,
  useDisclosure,
  Input,
  Textarea,
  Checkbox,
  ModalContent,
} from "@nextui-org/react";
import { useForm } from "react-hook-form";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import Toast from "./Alert";

function FaqMain() {
  const FAQS = [
    {
      question: "What should I do if I encounter issues with the website?",
      answer: (
        <>
          If you ever encounter an issue navigating through the site, please do
          not hesitate and submit a bug report at
          <Link to="https://astrobie.com/Support" className="text-[#1d65c1]">
            {" "}
            https://astrobie.com/Support
          </Link>
        </>
      ),
      section: "Popular",
    },
    {
      question: "How can I contact customer support?",
      answer: (
        <>
          If you experience any issues with our services or products, please
          contact us directly by submitting the form at
          <Link to="https://astrobie.com/Support" className="text-[#1d65c1]">
            {" "}
            https://astrobie.com/Support{" "}
          </Link>
          . Our team is committed to addressing your concerns promptly and
          effectively.
        </>
      ),
      section: "Popular",
    },
    {
      question: "Are the products covered by warranty?",
      answer: (
        <>
          All of our products are covered by a Limited Warranty policy. For more
          details, please review the warranty status of your products. If you
          require assistance with a specific case, do not hesitate to contact us
          directly at
          <Link to="mailto:customersupport@astrobie.com" className="text-[#1d65c1]">
            {" "}
            customersupport@astrobie.com
          </Link>
          .
        </>
      ),
      section: "Products",
    },
    {
      question: " Can I request customization for certain products?",
      answer: (
        <>
          Some products give you the option to customize the product while ordering it. However, customization after the product was delivered will not be covered by Astrobie LLC or directly made by Astrobie LLC. You can still ask for a quote by contacting
          <Link to="mailto:customersupport@astrobie.com" className="text-[#1d65c1]"> customersupport@astrobie.com</Link>
        </>
      ),
      section: "Products",
    },
    {
      question: "How do I apply to become a volunteer?",
      answer:
        (
          <>
            Whether you wish to volunteer as an individual or represent an institution interested in partnering with the Academy initiative, you can complete the volunteering form found in the “Resources” section at{" "}
            <Link to="https://astrobie.com/academy" className="text-[#1d65c1]"> astrobie.com/academy</Link>
            . There, you will find an online form for both individual volunteers and institutions; please select the option that best suits your needs.
          </>
        ),
      section: "Volunteers",
    },
    {
      question: "Can I volunteer remotely, or are all activities in person?",
      answer:
        "If you reside outside the United States and are interested in becoming a partner or volunteering for this cause, you are welcome to apply for a remote position. Remote roles are typically available for individuals located outside the U.S., and we currently have active members and institutions from various countries. We look forward to your involvement!",
      section: "Volunteers",
    },
    {
      question: "Can I support the project without volunteering?",
      answer:
        "Yes! We will soon introduce a donations channel where anyone can contribute. Institutions are also invited to join as partners, allowing us to develop more courses and answer for our learners in Africa, Asia, and Latin America. Your support will make a significant impact on our mission!",
      section: "Volunteers",
    },
    {
      question:
        "Can volunteering count towards school or work community service hours?",
      answer:
        'Yes! Many of our volunteers are collaborating with us, and through an arrangement between Astrobie LLC and their institutions, they have the opportunity to earn credits toward their degrees in technology fields where support is needed. We strongly encourage students to take advantage of this opportunity by filling out the form and checking the box that says, "I am a college student and would like to have this experience count toward my degree". so we can contact their institution. Please note that this option is available only for undergraduate and graduate students.',
      section: "Volunteers",
    },
    {
      question: "Can I organize a fundraiser for this project?",
      answer:
        " Yes! While individuals may not have the opportunity to collaborate with us directly, organizing a fundraiser for our cause is a great way to contribute. Please note that all fundraisers require explicit permission from Astrobie LLC, and an authorized member of Astrobie LLC must be present at all times. Any fundraising activities conducted without our authorization will be subject to legal action to protect our organization from unauthorized representations or financial exploitation.",
      section: "Volunteers",
    },
  ];

  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (
      /android/i.test(userAgent) ||
      (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
    ) {
      return true;
    }
    return window.innerWidth <= 767;
  };

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [isSelected, setIsSelected] = React.useState(false);

  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Handle the form submit
  const onSubmit = (data) => {
    //console.log(data);
    const questionRef = collection(db, "faq");
    addDoc(questionRef, data)
      .then((doc) => {
        onOpenChange(false);
        reset();
        Toast.fire({
          icon: "success",
          title: "Good question! Someone will review and answer your question",
        });
      })
      .catch((reason) => {
        Toast.fire({
          icon: "error",
          title: "We have had some problems, please try again.",
        });
      });
  };

  return (
    <section
      name="faqMain"
      className="p-0 m-0 py-36 bg-[#FDFFFC]  flex-col items-center justify-center w-[100%]"
    >
      <div className=" px-6 text-[#1D1D1D] mx-auto flex-column answer-center static gap-8 max-w-[1144px]">
        <div className="pl-2">
          <p className="text-4xl font-semibold text-left">
            Frequently Asked questions
          </p>
        </div>

        {/* Astrobie section */}
        <div className="items-center pl-6 mb-10 pt-10">
          <p className="pl-1 text-3xl md:text-2xl font-medium text-left">
            Astrobie
          </p>

          {/* each div is a section */}
          <div>
            <p className="mt-5 ml-5 text-2xl md:text-xl font-medium">Popular questions</p>
            <Accordion className="ms-10">
              {FAQS.filter((faq) => faq.section === "Popular").map(
                (faq, index) => (
                  <AccordionItem
                    classNames={{
                      title: "font-medium text-md md:text-sm",
                      content: "text-[#1d1d1d8e] font-normal text-sm md:text-xs",
                    }}
                    key={index}
                    aria-label={"FAQ " + index}
                    title={faq.question}
                  >
                    {faq.answer}
                  </AccordionItem>
                )
              )}
            </Accordion>
          </div>

          <div>
            <p className="mt-5 ml-5 text-2xl md:text-xl font-medium">Products</p>
            <Accordion className="ms-10">
              {FAQS.filter((faq) => faq.section === "Products").map(
                (faq, index) => (
                  <AccordionItem
                    classNames={{
                      title: "font-medium text-md md:text-sm",
                      content: "text-[#1d1d1d8e] font-normal text-sm md:text-xs",
                    }}
                    key={index}
                    aria-label={"FAQ " + index}
                    title={faq.question}
                  >
                    {faq.answer}
                  </AccordionItem>
                )
              )}
            </Accordion>
          </div>
        </div>

        {/* Academy section */}
        <div className="items-center pl-2 mb-10 pt-10">
          <p className="pl-1 text-3xl md:text-2xl font-medium text-left">
            Astrobie Academy
          </p>

          {/* each div is a section */}

          <div>
            <p className="mt-5 ml-5 text-2xl md:text-xl font-medium">Volunteers</p>
            <Accordion className="ms-10">
              {FAQS.filter((faq) => faq.section === "Volunteers").map(
                (faq, index) => (
                  <AccordionItem
                    classNames={{
                      title: "font-medium text-md md:text-sm",
                      content: "text-[#1d1d1d8e] font-normal text-sm md:text-xs",
                    }}
                    key={index}
                    aria-label={"FAQ " + index}
                    title={faq.question}
                  >
                    {faq.answer}
                  </AccordionItem>
                )
              )}
            </Accordion>
          </div>
        </div>

        <div className="grid grid-2 md:grid-cols-4 bg-[#F4F6F3] px-8 py-6 rounded-2xl mt-24">
          <div className="md:col-span-3">
            <p className="mb-2 text-lg font-semibold">Still have questions?</p>
            <p className="text-sm">
              <span className="font-semibold">
                Couldn't find what you were looking for?{" "}
              </span>
              Redact your personalized question and we'll get to you shortly.
            </p>
          </div>
          <div className="flex items-center justify-end mt-4 md:mt-0 md:justify-end">
            <Button
              onPress={onOpen}
              className="right-10 bg-[#1D65C1] rounded-full text-white"
              size="s"
            >
              Redact
            </Button>
          </div>
        </div>

        <Modal
          size="xl"
          isDismissable={!isMobile()}
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          onClose={() => {
            reset();
          }}
          className="bg-[#FDFFFC]"
        >
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <ModalContent>
              {(onClose) => (
                <>
                  <ModalHeader className="flex flex-col gap-1 text-xl">
                    Redact your question
                  </ModalHeader>
                  <ModalBody>
                    <p className="mt-4 font-semibold">Personal details:</p>
                    <Input
                      variant="flat"
                      isRequired
                      type="text"
                      label="Your Full Name"
                      {...register("fullName", {
                        required: "Full Name is required",
                      })}
                      isInvalid={errors.fullName ? true : false}
                      color={errors.fullName ? "#D84363" : ""}
                      errorMessage={errors.fullName?.message}
                    />
                    <Input
                      variant="flat"
                      isRequired
                      type="email"
                      label="Email Address"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^[a-z A-Z 0-9 . _ -]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: "Invalid email address",
                        },
                      })}
                      isInvalid={errors.email ? true : false}
                      color={errors.email ? "#D84363" : ""}
                      errorMessage={errors.email?.message}
                    />
                    <p className="mt-4 font-semibold">Your question:</p>
                    <Input
                      variant="flat"
                      isRequired
                      type="text"
                      label="question"
                      {...register("question", {
                        required: "question is required",
                      })}
                      isInvalid={errors.question ? true : false}
                      color={errors.question ? "#D84363" : ""}
                      errorMessage={errors.question?.message}
                    />
                    <Textarea
                      isRequired
                      label="Description"
                      variant="flat"
                      placeholder="Enter your description"
                      disableAnimation
                      disableAutosize
                      description="Enter a concise description of your question"
                      classNames={{
                        base: "max-w-full",
                        input: "resize-y min-h-[40px] max-w-[50vh]",
                      }}
                      {...register("description", {
                        required: "Description is required",
                      })}
                      isInvalid={errors.description ? true : false}
                      color={errors.description ? "#D84363" : ""}
                      errorMessage={errors.description?.message}
                    />
                    <div className="flex flex-row">
                      <Checkbox
                        onValueChange={setIsSelected}
                        {...register("acceptTerms", {
                          required: "You must accept the terms",
                        })}
                        isInvalid={errors.acceptTerms ? true : false}
                        color={errors.acceptTerms ? "#D84363" : ""}
                        size="sm"
                      >
                        I accept&nbsp;
                      </Checkbox>
                      <Link
                        to="terms-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="text-[#1D64C1] text-sm cursor-pointer">
                          terms and conditions
                        </span>
                      </Link>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      className="bg-transparent rounded-full border-1 border-[#1d1d1d59] text-[#1D1D1D] lg:hover:bg-[#1D1D1D] lg:hover:text-[#FDFFFC]"
                      onPress={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      isDisabled={!isSelected}
                      className="bg-[#1D64C1] rounded-full text-[#FDFFFC]"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          </form>
        </Modal>
      </div>
    </section>
  );
}
export default FaqMain;
