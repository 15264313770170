import React from "react";

import { motion } from "framer-motion";
import { PlasticSquares } from "./SquaresAnimations";
import { Parallax } from "react-scroll-parallax";

/*
    -title: this param writes the title of the section. ex: Plastic
    -subtitle: this param writes the second line like a subtitle. ex: Make sure you love it
    -description: this param writes a short paragraph explaining the material.
    -squares: this param adds the squares of all colors or types, please code this in SquareAnimations like a function and call it.
    -className: This param writes any extra style needed into the motion.div that contains the text.
*/
function MaterialSection({ title, subtitle, description, material, right = false, className }) {


  return (
    <Parallax className="" opacity={[6,0,"easeOut"]} speed={-5}>
      <div
        className={`grid grid-cols-1 md:grid-cols-2 space-y-0 gap-10 h-screen max-md:pt-[9vh] ${className} ${
          right ? "text-right" : ""
        }`}
      >
        <motion.div
          initial={{ opacity: -10, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 2 }}
          className={`flex text-left justify-center flex-col max-md:self-end max-md:mt-5  ${
            right ? "text-end md:order-last" : ""
          } `}
        >
          <p
            className={`bg-clip-text text-transparent text-4xl md:text-5xl font-semibold bg-gradient-to-r ${
              right
                ? "to-[#808996] from-[#FDFFFC]"
                : "from-[#808996] to-[#FDFFFC] "
            }`}
          >
            {title}
          </p>
          <p className={`text-sm mt-2 ${right ? "text-end" : "text-justify"}`}>
            {subtitle}
          </p>
          <p className={`text-sm mt-2 ${right ? "text-end" : "text-justify"}`}>
            {description}
          </p>
        </motion.div>

        <div className="max-md:h-[60vh]">
          <PlasticSquares material={material}/>
        </div>
      </div>
    </Parallax>
  );
}

export default MaterialSection;
